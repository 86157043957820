import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import starIcon from '../../assets/images/home/star.svg';
import TitleBar from '../../components/common/TitleBar';
import { useCookieCheck } from '../../hooks/authHooks';
import '../home/Category.css';


type CardData = {
    imageSrc: string;
    title: string;
    subtitle: string;
    productId: string;
};

type CategoryProps = {
    title: string;
    cards: CardData[];
    category?: null | string
};

const CategorySection: React.FC<CategoryProps> = ({ title, cards, category = null }) => {
    const navigate = useNavigate();
    const refData = useRef(title)
    const [isHovered, setIsHovered] = useState(false);
    const isLogged: any = useCookieCheck()

    // hover effects
    const handleMouseEnter = () => {
        if (isHovered) return;
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        if (!isHovered) return;
        setIsHovered(false);
    };

    // category title
    let categoryTitle = category ? category
        : title === 'RECOMMENDATIONS' ? 'Apps' : title


    // category path
    let categoryPath =
        category?.toLowerCase() === 'ai products' ? 'movies' :
            category?.toLowerCase() === 'movie' ? 'movies' :
                category?.toLowerCase() === 'course' ? 'courses' :
                    category?.toLowerCase() === 'game' ? 'games' :
                        category?.toLowerCase() === 'service' ? 'services'
                            : title === 'RECOMMENDATIONS' ? 'apps' : category?.toLowerCase()


    // click action
    const handleClick = (item: any) => {
        if (isLogged) {
            navigate(categoryPath ? `/explore/${categoryPath}/details/${item?.productId}` : '')
        } else {
            navigate('/auth')
        }
    }

    // render
    return (
        <div className="category">
            <TitleBar title={title} isLeft={true} isHover={isHovered} path={categoryPath} />
            <div className="w-full">
                <div
                    style={{ display: 'flex', gap: 30, overflowX: 'scroll' }}
                    // className="horizontal-scroll flex overflow-x-auto scrollbar-hide gap-5"
                    ref={refData as any}
                >
                    {cards.map((item: any) => (
                        <div
                            key={item.id}
                            className="w-[300px] lg:min-w-[300px] h-[305px] flex-shrink-0 flex flex-col rounded-2xl transition-all duration-300 
                           hover:border-white hover:border-2 hover:p-1 overflow-hidden cursor-pointer"
                            onClick={() => handleClick(item)}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div
                                className="h-[305px] relative rounded-2xl bg-cover bg-center"
                                style={{ backgroundImage: `url(${item.imageSrc})` }}
                            >
                                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-60 transition-opacity duration-300"></div>
                                <div className="flex justify-between relative z-10 h-full">

                                    <div className=" bg-[#e3f1f1] bg-opacity-80 flex-1 w-full px-3 flex flex-col gap-y-2  absolute bg-transparent rounded-lg" style={{ top: "70%" }}>
                                        <h3 className="text-white font-semibold text-sm uppercase">
                                            {item.title}
                                        </h3>
                                        <p className="text-white text-xs font-normal">
                                            {item.subtitle}
                                        </p>
                                        <p className="flex gap-2 items-center justify-start">
                                            <div className="flex justify-between w-[80%] py-1">
                                                <p className="flex items-center py-1 h-4">
                                                    <label className="text-[#00F0FB] text-med">4.6</label>
                                                    <img src={starIcon} alt="star" className="h-4 w-4 ml-1" />
                                                </p>
                                                <span className="bg-[#3B2C94] px-3 py-1 text-white text-xs rounded-2xl capitalize">
                                                    {categoryTitle}
                                                </span>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
}

export default CategorySection;
