import React from 'react';

interface FloatingButtonProps {
    name: string;
    title: string;
    avgResponseTime: string;
    imageUrl: string;
    onClick: () => void;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ name, title, avgResponseTime, imageUrl, onClick }) => {
    return (
        <button
            onClick={onClick}
            className="float-contact-btn fixed min-w-[300px] bottom-4 right-4 flex 
            items-center bg-gray-200 text-gray-800 rounded-full px-2 py-1"
        >
            <div className='w-20 h-20 pt-2'>
                <img src={imageUrl} alt={name} />
            </div>
            <div className="text-left flex flex-col justify-center">
                <p className="font-bold">{name}</p>
                {/* <p className="text-sm">{title}</p> */}
                <p className="text-xs">Avg response time: {avgResponseTime}</p>
            </div>
        </button>
    );
};

export default FloatingButton;