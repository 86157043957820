import { LineDraw } from "../GameDetailsScreen";
import RatingDisplay from "./RatingDisplay";

export interface RatingSectionProps {
    starDistribution: any;
    rating: number;
    reviewTitle: string;
    reviewText: string;
    reviewerName: string;
    reviewDate: string;
}

function RatingSection({ ratingData }: RatingSectionProps | any) {
    const {
        starDistribution,
        rating,
        reviewTitle,
        reviewText,
        reviewerName,
        reviewDate
    } = ratingData
    return (
        <div className="mb-3 bg-[#111924] rounded-2xl p-5">
            <h2 className="text-3xl font-bold mb-4">Ratings and Reviews</h2>
            <LineDraw />
            <div className="flex items-center mb-2">
                <div className="flex mb-1">
                    <RatingDisplay starDistribution={starDistribution} />
                </div>
            </div>

            <div className="rounded-lg">
                <p className="font-bold text-md flex items-center">
                    <span>{rating?.toFixed(1)}</span>
                    <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                        <path d="M9 0L12.1158 4.7114L17.5595 6.21885L14.0416 10.6381L14.2901 16.2812L9 14.301L3.70993 16.2812L3.95845 10.6381L0.440492 6.21885L5.88415 4.7114L9 0Z" fill="#FD8E1F" />
                    </svg>
                    <span className="text-grey-300 font-normal ms-1 me-2"> | </span>
                    <span className="text-[#6DDCFF]"> {reviewTitle}</span>
                </p>
                <p className="text-lg text-gray-300 my-2 text-white">
                    {reviewText}
                </p>
                <p className="text-lg text-white mt-2">{reviewerName}, {reviewDate}</p>
            </div>
        </div>
    )
}

export default RatingSection
