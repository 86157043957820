import TitleBar from '../../components/common/TitleBar';
import NewlyAddedCard from "./NewlyAddedCard";


type CardData = {
    imageSrc: string;
    title: string;
};

type CategoryProps = {
    title: string;
    cards: CardData[];
};

const NewlyAddedCategory: React.FC<CategoryProps> = ({ title, cards }) => {
    return (
        <div className="category">
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 8 }}>
                <h2 className="category-title mb-5">{title}</h2>
                <div>
                    <img src={ArrowIcon} alt='' style={{ width: 20, height: 20, marginTop: 7 }} />
                </div>
            </div> */}
            <TitleBar title={title} isLeft={true} />
            <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', gap: 6, overflowX: 'scroll' }}>
                    {cards.map((card, index) => (
                        <div className='me-4'>
                            <NewlyAddedCard card={card} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default NewlyAddedCategory;