import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../layout/layout.scss';

interface AppData {
  fullName: string;
  email: string;
  joinedOn: string;
  role: string;
}

interface AppDataTableProps {
  data: AppData[];
}

const MembersTable = () => {
  const navigate = useNavigate();
  const [appData, setAppData] = useState<AppData[]>([]);

  const getMembersData = () => {
    const token = Cookies.get('authToken');
    axios
      .get('https://api.lusso.dev/api/v1/users?size=1000', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setAppData(response?.data?.users);
      })
      .catch(() => {
      });
  };
  useEffect(() => {
    getMembersData();
  }, [])

  const AppDataTable: React.FC<AppDataTableProps> = ({ data }) => {
    const navigate = useNavigate();

    return (
      <div className='data-table-wrapper custom-scrollbar'>
        <table>
          <thead className="header-row">
            <tr>
              <th style={{ color: '#FFFFFF80', fontWeight: 500, fontSize: '1rem', textTransform: 'uppercase', textAlign: 'center' }}>Full Name</th>
              <th style={{ color: '#FFFFFF80', fontWeight: 500, fontSize: '1rem', textTransform: 'uppercase', textAlign: 'center' }}>Email Address</th>
              <th style={{ color: '#FFFFFF80', fontWeight: 500, fontSize: '1rem', textTransform: 'uppercase', textAlign: 'center' }}>Joined</th>
              <th style={{ color: '#FFFFFF80', fontWeight: 500, fontSize: '1rem', textTransform: 'uppercase', textAlign: 'center' }}>Permissions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((app, index) => (
              <tr
                onClick={() => { navigate('/dev/dashboard/productdetails') }}
                key={index}
                className="prouduct-data-row">
                <td style={{ textAlign: 'center' }}>{app.fullName}</td>
                <td style={{ textAlign: 'center' }}>{app.email}</td>
                <td style={{ textAlign: 'center' }}>{app.joinedOn}</td>
                <td className='flex flex-row items-center justify-center' style={{ textAlign: 'center' }}>
                  <div className='flex flex-row gap-2 items-center justify-center'>
                    <button
                      style={{
                        backgroundColor:
                          app.role === 'Editor' ? '#1F2124' :
                            app.role === 'Admin' ? '#E73877' :
                              app.role === 'User' ? '#025E86' :
                                '',
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingLeft: 12,
                        paddingRight: 12,
                        borderRadius: 8
                      }}
                    >
                      {app.role}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };



  return (
    <div className="members-table">
      <div className='flex flex-1 flex-row items-center justify-between'>
        <div>
          <input
            placeholder='Search Members'
            className='table-searchInput'
            style={{ color: "#FFF" }}
          />
        </div>
        <div>
          <button
            onClick={() => { navigate('/dev/addmember') }}
            style={{ color: '#FFF', fontWeight: 600, paddingLeft: 16, paddingRight: 16 }}
            className='AddProductButton'
          >
            + New User
          </button>
        </div>
      </div>
      <div className='members-data-table'>
        <AppDataTable data={appData} />
      </div>
      <div style={{ color: '#FFF', fontWeight: 400, fontSize: '0.7rem', paddingTop: 8 }}>
        <span>Result 10 of 20 records</span>
      </div>
    </div>
  )
}

export default MembersTable;