import { useState } from 'react';
import bannerImg from '../../../assets/images/explore/category/movies/moviebanner.png';
import logo from '../../../assets/images/explore/category/movies/movielogo.png';
import GameCard from '../../../components/cards/GameCard';
import { moviesData } from '../../home/HomeExplore';

import CategorySection from '../CategorySection';
import { topTrendingData } from '../ExploreAll';
import AdditionalInfo from '../categoryDetails/Common/AdditionalInfo';
import DescriptionSection from '../categoryDetails/Common/DescriptionSection';
import RatingSection, { RatingSectionProps } from '../categoryDetails/Common/RatingSection';
import AvailableAt from './AvailableAt';
import Cast from './Cast';
import Details from './Details';
import Trailor from './Trailor';
import Header from '../categoryDetails/Common/Header';
import MainBannerWithHeader from '../categoryDetails/Common/MainBannerWithHeader';



export interface HeaderDataType {
    logo: string;
    title: string;
    rating: number;
    ratingCount: string;
    category: string;
    ageRating: string;
    desc: string
}


export const LineDraw = () => (
    <svg className='mb-5' xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 1077 2" fill="none">
        <path opacity="0.2" d="M4.37222e-08 0.999906L1077 1" stroke="white" />
    </svg>
)

const MovieDetailsScreen = () => {
    const [selectedOption, setSelectedOption] = useState('Subscription');

    const [headerData, setHeaderData] = useState<HeaderDataType>({
        logo: logo,
        title: 'Opeenheimer',
        rating: 4.5,
        ratingCount: '330k',
        category: 'Entertainment',
        ageRating: '12+',
        desc: `Looking for the most talked about TV shows and movies from around the world? They're all on Netflix.
        We've got award-winning series, movies, documentaries, and stand-up specials. And with the mobile app, you get
        Netflix while you travel, commute, or just take a break.`
    });

    const [starDistribution, setStarDistribution] = useState([
        { stars: 5, percentage: 70 },
        { stars: 4, percentage: 20 },
        { stars: 3, percentage: 5 },
        { stars: 2, percentage: 3 },
        { stars: 1, percentage: 2 },
    ]);


    const ratingData: RatingSectionProps = {
        starDistribution: starDistribution,
        rating: 4.5,
        reviewTitle: "App is one of the best",
        reviewText: "I have been using Netflix since it came out pretty much, I've always enjoyed it and I have loved everything they have done with the App , it is one of the best streaming platform, provides great quality but the subscription is very expensive, we get only one screen in UE this need to be addressed by Netflix",
        reviewerName: "Ben Cutting",
        reviewDate: "8/27/2024"
    };

    const additionalInfoData = {
        publisher: "Netflix, Inc.",
        genres: "Entertainment",
        category: "Apps/Entertainment",
        productSize: "50 GB",
        ageRating: "PG-18",
        support: {
            phoneNumber: "+18667160414",
            email: "iosappstore@netflix.com"
        },
        compatibility: {
            devices: [
                "iPhone",
                "iPad",
                "iPod touch"
            ],
            requirements: [
                "Requires iOS 12.0 or later.",
                "Requires iPadOS 12.0 or later."
            ]
        }
    };

    const MainBannerWithHeaderData = () => (
        <div className="relative w-full md:h-[50rem] overflow-hidden rounded-lg">
            {/* Blurred background image */}
            <div
                className="hidden md:block md:absolute inset-0 bg-cover bg-center"
                style={{
                    backgroundImage: `url(${bannerImg})`,
                    filter: 'blur(2px)',
                    transform: 'scale(1.1)'
                }}
            />

            {/* Gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent" />

            {/* Content */}
            <div className="relative h-full flex justify-center items-end">
                <div className='content-new'>
                    <div className="lg:max-w-[80%] mx-auto px-4 py-8">
                        <Header headerData={headerData} />
                    </div>
                </div>
            </div>
        </div>
    )

    // render
    return (
        <div className="alignCenter bg-blurred-new text-white min-h-screen">
            <MainBannerWithHeader bannerImg={bannerImg} headerData={headerData} />
            {/* <MainBanner /> */}
            <div className='content-new'>
                <div className="lg:max-w-[80%] mx-auto px-4 py-8">
                    {/* <Header headerData={headerData} /> */}
                    <div className="flex flex-col md:flex-row md:space-x-8">
                        <MainContent
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            headerData={headerData}
                            starDistribution={starDistribution}
                            ratingData={ratingData}
                            additionalInfoData={additionalInfoData}
                        />
                        <Sidebar />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MainBanner = () => (
    <div className="explore-cat-banner">
        {/* This could be an image or video */}
        <img src={bannerImg} alt="Movie Banner" className="explore-cat-banner" />
    </div>
);


const MainContent = (props: any) => (
    <div className="w-full md:w-2/3">

        <Trailor />
        <AvailableAt />
        <DescriptionSection desc={props.headerData?.desc} />
        <Details selectedOption={props?.selectedOption} setSelectedOption={props?.setSelectedOption} />
        <Cast />
        <AdditionalInfo infoData={props?.additionalInfoData} />
        {/* <GameDescription desc={props.headerData?.desc} /> */}
        {/* <InfoSection /> */}
        <RatingSection ratingData={props?.ratingData} />
        {/* Recommendation */}
        <div className='mb-5 px-0'>
            <CategorySection title="Recomendation" cards={topTrendingData} category={'Ai'} />
        </div>
    </div>
);


const Sidebar = () => (
    <div className="w-full md:w-1/3 mt-8 md:mt-0">
        <h2 className="text-xl font-bold mb-4">PEOPLE ALSO VIEW</h2>
        <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
            {moviesData.map((game, index) => (
                <GameCard key={index} game={game} bg={'rgba(121, 47, 255, 0.15)'} />
            ))}
        </div>
    </div>
);

export default MovieDetailsScreen