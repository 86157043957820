import { useNavigate } from 'react-router-dom';
import CardIcon from '../../assets/images/icons/card-icon.svg';
import CardTypeIcon from '../../assets/images/icons/card-type.svg';
import DeleteIcon from '../../assets/images/icons/delete-icon.svg';
import TickIcon from '../../assets/images/tick-icon.svg';

const Purchased = () => {
    const navigate = useNavigate();
    return (
        <div className="container price-background-banner">
            <div className="left"></div>
            <div className="right">
                <div className='text-white font-bold text-[20px]'>
                    <span>Your Plan Details</span>
                </div>
                <div>
                    <div className="horizontal-divider-light mt-4 mb-4"></div>
                </div>
                <div className="flex flex-col gap-y-4">
                    <div className="purchased-card px-4 py-4">
                        <div >
                            <span className="text-white px-3 py-2" style={{ background: '#3B3396', fontSize: '0.8rem', borderRadius: 16 }}>
                                CURRENT PLAN
                            </span>
                        </div>
                        <div className="flex flex-row justify-between items-center current-plan-detail-row">
                            <div className="flex flex-col py-4">
                                <span className="font-bold text-[#FF56E5]" style={{ fontSize: '1.5rem' }}>
                                    Standard
                                </span>
                                <span className="font-normal text-[#FFF]" style={{ fontSize: '0.8rem' }}>
                                    Standard monthly subscription plan active since: May1, 2024
                                </span>
                            </div>
                            <div className='current-plan-btn-row flex flex-row justify-center items-center gap-2'>
                                <button
                                    className="saveContinue"
                                    style={{
                                        borderRadius: 8,
                                        border: '1px solid #FFF',
                                        textTransform: 'capitalize',
                                        paddingLeft: 12,
                                        paddingRight: 12
                                    }}
                                >
                                    Cancel Plan
                                </button>
                                <button
                                    onClick={() => {
                                        navigate('/dev/pricing')
                                    }}
                                    className="saveContinue"
                                    style={{
                                        borderRadius: 8,
                                        border: '1px solid #A768FD',
                                        background: 'linear-gradient(90deg, #4B03CE 0%, #F572B6 100%)',
                                        textTransform: 'capitalize',
                                        paddingLeft: 12,
                                        paddingRight: 12
                                    }}
                                >
                                    Upgrade
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="current-next-row flex flex-row gap-x-2">
                        <div className="flex-1 purchased-card px-4 py-4">
                            <div>
                                <span className="text-white font-normal" style={{ fontSize: '0.9rem' }}>
                                    Current Plans Benefits
                                </span>
                            </div>
                            <div className='flex flex-row mt-4'>
                                <div className="flex-1 flex flex-col gap-y-2">
                                    <div className='flex flex-row gap-x-2'>
                                        <span>
                                            <img src={TickIcon} alt='' />
                                        </span>
                                        <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
                                            Feature X
                                        </span>
                                    </div>
                                    <div className='flex flex-row gap-x-2'>
                                        <span>
                                            <img src={TickIcon} alt='' />
                                        </span>
                                        <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
                                            Feature X
                                        </span>
                                    </div>
                                    <div className='flex flex-row gap-x-2'>
                                        <span>
                                            <img src={TickIcon} alt='' />
                                        </span>
                                        <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
                                            Feature X
                                        </span>
                                    </div>
                                </div>
                                <div className="flex-1 flex flex-col gap-y-2">
                                    <div className='flex flex-row gap-x-2'>
                                        <span>
                                            <img src={TickIcon} alt='' />
                                        </span>
                                        <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
                                            Feature X
                                        </span>
                                    </div>
                                    <div className='flex flex-row gap-x-2'>
                                        <span>
                                            <img src={TickIcon} alt='' />
                                        </span>
                                        <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
                                            Feature X
                                        </span>
                                    </div>
                                    <div className='flex flex-row gap-x-2'>
                                        <span>
                                            <img src={TickIcon} alt='' />
                                        </span>
                                        <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
                                            Feature X
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="flex-1 purchased-card px-4 py-4">
                            <div className='flex flex-row justify-between items-center'>
                                <div>
                                    <div>
                                        <span className="text-white font-normal" style={{ fontSize: '0.9rem' }}>
                                            Current Plans Benefits
                                        </span>
                                    </div>
                                    <div>
                                        <span className="text-white font-normal" style={{ fontSize: '1.5rem' }}>
                                            $ 199.00
                                        </span>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: '#F927D9', paddingLeft: 12, paddingRight: 12, paddingBottom: 16, paddingTop: 16, borderRadius: '50%' }}>
                                    <img src={CardIcon} alt='' />
                                </div>
                            </div>
                            <div className='flex flex-col justify-between mt-3'>
                                <div className='flex flex-row'>
                                    <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
                                        Plan type:
                                    </span>
                                    <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
                                        Standard (monthly)
                                    </span>
                                </div>
                                <div className='flex flex-row mt-1'>
                                    <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
                                        Next bill date:
                                    </span>
                                    <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
                                        Jun 15, 2024
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="payment-method-row flex flex-row gap-x-2">
                        <div className="purchased-card px-4 py-4 flex flex-col justify-between" style={{ width: '30%' }}>
                            <div>
                                <span className="text-[#FF56E5] font-normal" style={{ fontSize: '0.9rem' }}>
                                    Payment Method
                                </span>
                            </div>
                            <div className='flex flex-row items-center justify-between'>
                                <div>
                                    <img src={CardTypeIcon} alt='' />
                                </div>
                                <div>
                                    <span className='text-white'>**** 9519</span>
                                </div>
                            </div>
                            <div className='flex flex-col justify-between mt-3'>
                                <div className='flex flex-row items-center'>
                                    <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
                                        Name on Card:
                                    </span>
                                    <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
                                        Robert Downey
                                    </span>
                                </div>
                                <div className='flex flex-row mt-1 items-center'>
                                    <span className="text-white font-normal" style={{ fontSize: '0.8rem', width: '40%' }}>
                                        Expiration date:
                                    </span>
                                    <span className="text-white font-normal" style={{ fontSize: '0.8rem' }}>
                                        5/27
                                    </span>
                                </div>
                            </div>
                            <div className='change-delete-btn-row flex flex-row items-center gap-1 mt-1'>
                                <button
                                    className="saveContinue"
                                    style={{
                                        borderRadius: 8,
                                        border: '1px solid #FFF',
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    Change
                                </button>
                                <div style={{ backgroundColor: '#FFF', padding: 8, borderRadius: '50%' }}>
                                    <img src={DeleteIcon} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 purchased-card">
                            <div className='px-4 py-4'>
                                <span className="text-[#FF56E5] font-normal" style={{ fontSize: '0.9rem' }}>
                                    Payment Method
                                </span>
                            </div>
                            <div className='subscriptions' style={{ overflow: 'auto', maxHeight: 300 }}>
                                <div className='flex flex-row px-4'>
                                    <span className='flex-1 text-white'>Date</span>
                                    <span className='flex-1 text-white'>Payment Type</span>
                                    <span className='flex-1 text-white'>Amount</span>
                                </div>
                                <div style={{ border: '1px #FFF solid' }}></div>
                                <div className='flex flex-row px-4 py-1'>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
                                </div>
                                <div style={{ border: '0.1px gray solid' }}></div>
                                <div className='flex flex-row px-4 py-1'>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
                                </div>
                                <div style={{ border: '0.1px gray solid' }}></div>
                                <div className='flex flex-row px-4 py-1'>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
                                </div>
                                <div style={{ border: '0.1px gray solid' }}></div>
                                <div className='flex flex-row px-4 py-1'>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
                                </div>
                                <div style={{ border: '0.1px gray solid' }}></div>
                                <div className='flex flex-row px-4 py-1'>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
                                </div>
                                <div style={{ border: '0.1px gray solid' }}></div>
                                <div className='flex flex-row px-4 py-1'>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Apr 10, 2024 at 10:30 PM</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>Standard subcription (monthly)</span>
                                    <span className='flex-1 text-[#FFF] font-normal text-[13px] p-2'>$199.00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Purchased;