import { useNavigate } from "react-router-dom";
import icon1 from '../../assets/images/home/happen-with-lusso/1.svg';
import icon2 from '../../assets/images/home/happen-with-lusso/2.svg';
import icon3 from '../../assets/images/home/happen-with-lusso/3.svg';
import icon4 from '../../assets/images/home/happen-with-lusso/4.svg';
import { ExploreButton } from "./AIMarketplace";

interface FeatureCardType {
    icon: any
    title: string
    description: string
}

const features = [
    {
        icon: icon1,
        title: 'Expanding reach to audiences',
        description: 'Connect with a wider audience and grow your influence across various platforms.'
    },
    {
        icon: icon2,
        title: 'Gaining insights in to product performance and social impact',
        description: `Analyze your product's performance and understand its social impact with comprehensive data.`
    },
    {
        icon: icon3,
        title: 'Simplifying user engagement with automated tools',
        description: 'Streamline your user interactions with powerful automation tools for enhanced engagement.'
    },
    {
        icon: icon4,
        title: 'Detailed social media analytics',
        description: 'Get in-depth insights into your social media performance with comprehensive analytics tools.'
    },
];

const FeatureCard = ({ icon, title, description }: FeatureCardType) => {
    return (
        <div className="md:h-[250px] cursor-pointer relative group p-6 rounded-lg overflow-hidden transition-all duration-300 ease-in-out">
            <div className="flex flex-col items-center text-center h-full">
                <div className="w-[65px] h-[70px] flex items-center justify-center text-white mb-4 transition-all duration-300 ease-in-out group-hover:scale-110">
                    <img src={icon} alt={title} />
                </div>
                <h3 className="text-white text-md font-semibold mb-5 transition-opacity duration-300 group-hover:opacity-0">
                    {title}
                </h3>
                <div className="absolute mt-[40px] inset-0 flex items-center justify-center p-4 
                opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                    <p className="text-gray-300 text-sm">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
};

const LussoSection = ({ isLogged = false }: any) => {
    const navigate = useNavigate()

    // render
    return (
        <div className="py-10 px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl md:text-5xl font-bold text-center text-white mb-[80px]">
                MAKE IT HAPPEN WITH LUSSO
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-8">
                {features.map((feature, index) => (
                    <FeatureCard key={index} {...feature} />
                ))}
            </div>
            {isLogged === false && <div className="flex justify-center">
                <ExploreButton onClick={() => navigate('/auth/devonboard')} title={'Join Now'} />
            </div>}
        </div>
    );
};

export default LussoSection;