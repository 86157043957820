import React, { useState } from "react";
import FAQ from "../../../components/common/FAQ";
import Button from "../../../components/ui/Button";
import Input from "../../../components/ui/Input";
import { ICON_ENUM } from "../../../constants/icons.constant";

const questions: { question: string, answer: string }[] = [
    { question: "How does your platform handle product promotion?", answer: 'This is a placeholder answer for the FAQ item.' },
    { question: "Can I track my product's performance?", answer: 'This is a placeholder answer for the FAQ item.' },
    { question: "How can I integrate my listings with other platforms?", answer: 'This is a placeholder answer for the FAQ item.' },
    { question: "How can I contact customer support?", answer: 'This is a placeholder answer for the FAQ item.' },
];

const ContactUs = () => {
    const [formData, setFormData] = useState<{ [key: string]: string }>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        listOfServices: '',
        helpMessage: '',
    })

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        setFormData(prev => ({ ...prev, [name]: value }))
    }
    return (
        <>
            <div className="contact-background-banner flex flex-col justify-center items-center gap-10 px-5 lg:px-10 py-24">
                <h1 className="text-5xl font-bold">Get in touch</h1>
                <p className="text-xl p-3">
                    Can’t find the solution you’re looking for? Here’s how to get help from our experts.</p>
                <div className="join bg-[#FFFFFF1A] rounded-full p-2 w-full lg:w-1/2">
                    <input className="input join-item bg-transparent w-full" placeholder="Search" />
                    <button className="btn bg-gradient-vertical rounded-full text-white py-2 lg:px-6"><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70">
                        <path
                            fillRule="evenodd"
                            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                            clipRule="evenodd" />
                    </svg>
                        <span className="font-bold">Search</span>
                        <div><img src={ICON_ENUM.STAR_GROUP.icon} alt='star-group' className='h-3' /></div></button>
                </div>
            </div>
            <div className="home-price-background-banner px-10 py-5 flex flex-col gap-2">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="flex items-center">
                        <div className="flex flex-col gap-5 lg:w-1/2">
                            <h1 className="text-3xl font-bold">Feel free to <span className="text-primary-custom">reach out</span> to us for any inquiries or assistance directly</h1>
                            <div className="flex items-center gap-5 p-3 border border-[#FFFFFF57]">
                                <div><img src={ICON_ENUM.EMAIL.icon} alt="email" className="h-5" /></div>
                                <span>Lusso.ai</span>
                            </div>
                        </div>
                    </div>
                    <div className="card-bg-dev rounded-2xl px-5 lg:px-16 py-16 opacity-70 text-white space-y-3">
                        <Input
                            label="First Name"
                            name="firstName"
                            placeholder="Enter your first name"
                            value={formData?.firstName ?? ''}
                            onChange={onChange}
                        // error={validation?.username?.error ?? false}
                        // errorMessage={validation?.username?.errorMessage ?? ''}
                        />
                        <Input
                            label="Last Name"
                            name="lastName"
                            placeholder="Enter your last name"
                            value={formData?.lastName ?? ''}
                            onChange={onChange}
                        // error={validation?.username?.error ?? false}
                        // errorMessage={validation?.username?.errorMessage ?? ''}
                        />
                        <Input
                            label="Email"
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={formData?.email ?? ''}
                            onChange={onChange}
                        // error={validation?.username?.error ?? false}
                        // errorMessage={validation?.username?.errorMessage ?? ''}
                        />
                        <Input
                            label="Phone Number"
                            type="phone"
                            name="phone"
                            placeholder="Enter your phone"
                            value={formData?.phone ?? ''}
                            onChange={onChange}
                        // error={validation?.username?.error ?? false}
                        // errorMessage={validation?.username?.errorMessage ?? ''}
                        />
                        <Input
                            label="I am interested in:"
                            name="listOfServices"
                            placeholder="List of services"
                            value={formData?.listOfServices ?? ''}
                            onChange={onChange}
                        // error={validation?.username?.error ?? false}
                        // errorMessage={validation?.username?.errorMessage ?? ''}
                        />
                        <Input
                            label="How can we help you?"
                            name="helpMessage"
                            type="textarea"
                            placeholder=""
                            value={formData?.helpMessage ?? ''}
                            onTextareaChange={onChange}
                        // error={validation?.username?.error ?? false}
                        // errorMessage={validation?.username?.errorMessage ?? ''}
                        />
                        <Button
                            label="Send Message"
                            className="bg-gradient-vertical w-full py-2 rounded-full"
                        // onClick={handleNavigation}
                        />
                    </div>
                </div>
                <FAQ questions={questions} />
            </div>
        </>)
}

export default ContactUs