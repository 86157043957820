export const checkNullOrEmpty = value => {
  return value === undefined || value === null || value === '';
};

export const regixValidation = (value, regix) => {
  if (checkNullOrEmpty(value)) return {isValid: true, msg: ''};
  if (!regix?.pattern?.test(value)) {
    return {isValid: false, msg: regix?.msg ?? ''};
  }

  return {isValid: true, msg: ''};
};

export const BusinessTypeOptions = [
  {value: 'Technology', label: 'Technology'},
  {value: 'Healthcare', label: 'Healthcare'},
  {value: 'Finance', label: 'Finance'},
  {value: 'Retail', label: 'Retail'},
  {value: 'Manufacturing', label: 'Manufacturing'},
  {value: 'Energy', label: 'Energy'},
  {value: 'Education', label: 'Education'},
  {value: 'Real Estate', label: 'Real Estate'},
  {value: 'Telecommunications', label: 'Telecommunications'},
  {
    value: 'Transportation and Logistics',
    label: 'Transportation and Logistics',
  },
  {value: 'Agriculture', label: 'Agriculture'},
  {value: 'Automotive', label: 'Automotive'},
  {value: 'Hospitality and Tourism', label: 'Hospitality and Tourism'},
  {value: 'Media and Entertainment', label: 'Media and Entertainment'},
  {value: 'Food and Beverage', label: 'Food and Beverage'},
  {value: 'Construction', label: 'Construction'},
  {value: 'Aerospace and Defense', label: 'Aerospace and Defense'},
  {value: 'Biotechnology', label: 'Biotechnology'},
  {value: 'Chemicals', label: 'Chemicals'},
  {value: 'Consumer Goods', label: 'Consumer Goods'},
  {value: 'E-commerce', label: 'E-commerce'},
  {value: 'Environmental Services', label: 'Environmental Services'},
  {value: 'Insurance', label: 'Insurance'},
  {value: 'Legal Services', label: 'Legal Services'},
  {value: 'Mining and Extraction', label: 'Mining and Extraction'},
  {value: 'Pharmaceuticals', label: 'Pharmaceuticals'},
  {value: 'Publishing', label: 'Publishing'},
  {
    value: 'Public Services and Government',
    label: 'Public Services and Government',
  },
  {value: 'Sports and Recreation', label: 'Sports and Recreation'},
  {value: 'Textiles and Apparel', label: 'Textiles and Apparel'},
  {
    value: 'Venture Capital and Private Equity',
    label: 'Venture Capital and Private Equity',
  },
  {value: 'Water and Waste Management', label: 'Water and Waste Management'},
  {value: 'Advertising and Marketing', label: 'Advertising and Marketing'},
  {value: 'Arts and Culture', label: 'Arts and Culture'},
  {value: 'Information Services', label: 'Information Services'},
  {value: 'Human Resources', label: 'Human Resources'},
  {
    value: 'Professional Services (Consulting)',
    label: 'Professional Services (Consulting)',
  },
  {value: 'Shipping and Maritime', label: 'Shipping and Maritime'},
  {
    value: 'Event Planning and Management',
    label: 'Event Planning and Management',
  },
  {
    value: 'Non-Profit and Social Services',
    label: 'Non-Profit and Social Services',
  },
  {
    value: 'Telecommunications Equipment',
    label: 'Telecommunications Equipment',
  },
  {value: 'Warehousing and Storage', label: 'Warehousing and Storage'},
  {value: 'Investment Management', label: 'Investment Management'},
  {value: 'Medical Devices', label: 'Medical Devices'},
  {value: 'Renewable Energy', label: 'Renewable Energy'},
  {value: 'Freight and Cargo', label: 'Freight and Cargo'},
  {value: 'Oil and Gas', label: 'Oil and Gas'},
  {value: 'Robotics and Automation', label: 'Robotics and Automation'},
  {
    value: 'Video Games and Interactive Media',
    label: 'Video Games and Interactive Media',
  },
  {value: 'Food Production', label: 'Food Production'},
  {value: 'Fashion and Apparel', label: 'Fashion and Apparel'},
  {value: 'Luxury Goods', label: 'Luxury Goods'},
  {
    value: 'Data Analytics and Business Intelligence',
    label: 'Data Analytics and Business Intelligence',
  },
  {
    value: 'Blockchain and Cryptocurrency',
    label: 'Blockchain and Cryptocurrency',
  },
  {
    value: 'Artificial Intelligence and Machine Learning',
    label: 'Artificial Intelligence and Machine Learning',
  },
  {value: 'Cybersecurity', label: 'Cybersecurity'},
  {
    value: 'Space Exploration and Technology',
    label: 'Space Exploration and Technology',
  },
  {value: 'Beauty and Personal Care', label: 'Beauty and Personal Care'},
  {value: 'Industrial Engineering', label: 'Industrial Engineering'},
  {
    value: 'Event Production and Planning',
    label: 'Event Production and Planning',
  },
];
