import { useEffect, useState } from 'react';
import { default as bannerImg, default as screeshot1 } from '../../../assets/images/explore/category/ai/BingAI4.jpeg';
import logo from '../../../assets/images/explore/category/ai/logo.svg';
import screeshot2 from '../../../assets/images/explore/category/ai/s2.png';
import screeshot3 from '../../../assets/images/explore/category/ai/s3.png';
import starIcon from '../../../assets/images/explore/category/games/star.svg';
import GameCard from '../../../components/cards/GameCard';
import { topTrendingData } from '../../home/HomeExplore';
import CategorySection from '../CategorySection';
import AdditionalInfo from './Common/AdditionalInfo';
import DescriptionSection from './Common/DescriptionSection';
import InfoSection from './Common/InfoSection';
import RatingSection, { RatingSectionProps } from './Common/RatingSection';
import Screenshots from './Common/Screenshots';
import MainBannerWithHeader from './Common/MainBannerWithHeader';

export interface HeaderDataType {
    logo: string;
    title: string;
    rating: number;
    ratingCount: string;
    category: string;
    ageRating: string;
    desc: string
}

const AiDetailsScreen = () => {
    const [selectedOption, setSelectedOption] = useState('Subscription');

    const [headerData, setHeaderData] = useState<HeaderDataType>({
        logo: logo,
        title: 'Bing: Chat with Ai & GPT-4',
        rating: 4.5,
        ratingCount: '330k',
        category: 'Entertainment',
        ageRating: '12+',
        desc: `Bing is an AI search engine powered by GPT-4\nBing has taken the power of GPT-4 in partnership with OpenAI and enhanced it with real-time information and citations.\nAnd it's free!!!`
    });

    const screenShotData = [
        screeshot1,
        screeshot2,
        screeshot3
    ]
    const [starDistribution, setStarDistribution] = useState([
        { stars: 5, percentage: 70 },
        { stars: 4, percentage: 20 },
        { stars: 3, percentage: 5 },
        { stars: 2, percentage: 3 },
        { stars: 1, percentage: 2 },
    ]);

    const ratingData: RatingSectionProps = {
        starDistribution: starDistribution,
        rating: 4.5,
        reviewTitle: "App is one of the best",
        reviewText: "I have been using Netflix since it came out pretty much, I've always enjoyed it and I have loved everything they have done with the App , it is one of the best streaming platform, provides great quality but the subscription is very expensive, we get only one screen in UE this need to be addressed by Netflix",
        reviewerName: "Ben Cutting",
        reviewDate: "8/27/2024"
    };

    const infoSectionData = {
        title: "Experience & qualification",
        data: [
            "Unleash the power of 𝐆𝐏𝐓-𝟒 in your searches to get straight to your answer instead of scrolling through endless links",
            "Bing will come back with a relevant, sourced summary to save your time and effort",
            "𝐀 𝐆𝐏𝐓-𝟒 𝐩𝐨𝐰𝐞𝐫𝐞𝐝 𝐰𝐫𝐢𝐭𝐢𝐧𝐠 𝐚𝐬𝐬𝐢𝐬𝐭𝐚𝐧𝐭",
            "Write an email",
            "Create a 5-day itinerary for a dream vacation to Hawaii",
            "Prepare for a job interview",
            "Design a quiz for trivia night",
            "Craft poems",
            "Compose rap lyrics",
            "Weave tales with ease",
            "Read more",
            "𝐀 𝐜𝐫𝐞𝐚𝐭𝐢𝐯𝐞 𝐢𝐦𝐚𝐠𝐞 𝐠𝐞𝐧𝐞𝐫𝐚𝐭𝐨𝐫",
            "Just dream it, type it, and let Bing create gorgeous images for 𝐅𝐑𝐄𝐄",
            "𝐀𝐧 𝐚𝐜𝐜𝐮𝐫𝐚𝐭𝐞 𝐥𝐚𝐧𝐠𝐮𝐚𝐠𝐞 𝐭𝐫𝐚𝐧𝐬𝐥𝐚𝐭𝐨𝐫 𝐚𝐧𝐝 𝐨𝐩𝐭𝐢𝐦𝐢𝐳𝐞𝐫",
            "Translate multiple languages",
            "Proofread and optimize content in various languages All using 𝐆𝐏𝐓-𝟒",
            "𝐀 𝐟𝐚𝐢𝐭𝐡𝐟𝐮𝐥 𝐚𝐧𝐝 𝐫𝐞𝐥𝐢𝐚𝐛𝐥𝐞 𝐀𝐈-𝐩𝐨𝐰𝐞𝐫𝐞𝐝 𝐜𝐨𝐩𝐢𝐥𝐨𝐭",
            "Understand your intent and provide accurate information",
            "Personalize responses based on your preferences and previous interactions",
            "Tell jokes, create stories, and even play games with you",
            "Experience the safest, most powerful way to search, chat, and find inspiration in a single app Built on the power of 𝐆𝐏𝐓-𝟒"
        ],
        linkPath: ""
    }

    const additionalInfoData = {
        publisher: "Netflix, Inc.",
        genres: "Entertainment",
        category: "Apps/Entertainment",
        productSize: "50 GB",
        ageRating: "PG-18",
        compatibility: {
            devices: [
                "iPhone",
                "iPad",
                "iPod touch"
            ],
            requirements: [
                "Requires iOS 12.0 or later.",
                "Requires iPadOS 12.0 or later."
            ]
        }
    };

    // init
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },);


    // render
    return (
        <div className="alignCenter bg-blurred-new text-white min-h-screen">
            {/* <MainBanner /> */}
            <MainBannerWithHeader bannerImg={bannerImg} headerData={headerData} />

            <div className='content-new'>
                <div className="lg:max-w-[80%] mx-auto px-4 py-8">
                    {/* <Header headerData={headerData} /> */}
                    <div className="flex flex-col md:flex-row md:space-x-8">
                        <MainContent
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            headerData={headerData}
                            ratingData={ratingData}
                            screenshotData={screenShotData}
                            infoSectionData={infoSectionData}
                            additionalInfoData={additionalInfoData}
                        />
                        <Sidebar />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MainBanner = () => (
    <div className="explore-cat-banner">
        {/* This could be an image or video */}
        <img src={bannerImg} alt="FIFA 24 Banner" className="explore-cat-banner" />
    </div>
);

const Header = ({ headerData }: any) => (
    <div className="flex items-center mb-8 gap-5">
        <div className='w-[200px] h-[200px] rounded-2xl'>
            <img src={headerData?.logo} alt={`${headerData?.title} logo`} />
        </div>
        <div className='flex flex-col justify-between gap-3'>
            <h1 className="text-4xl font-bold">{headerData?.title}</h1>
            <div className="flex items-center gap-2">
                <p className="text-white text-md flex flex-wrap md:flex-nowrap items-center">
                    <span>{headerData?.rating}</span>
                    <div className='h-4 w-4 mx-2'>
                        <img src={starIcon} alt='star' />
                    </div>
                    <span> | {headerData?.ratingCount} ratings | {headerData?.category}</span>
                </p>
            </div>
            <div className="flex flex-col gap-3">
                <div>
                    <p className='text-[#6DDCFF] font-semibold'>Category</p>
                    <p className='text-white'>{headerData.category}</p>
                </div>
                <div>
                    <p className='text-[#6DDCFF] font-semibold'>Age Rating</p>
                    <p className='text-white'>{headerData.ageRating}</p>
                </div>
            </div>
        </div>
    </div>
);

const MainContent = (props: any) => (
    <div className="w-full md:w-2/3">
        <Screenshots
            screenshotData={props?.screenshotData}
            title={'Screenshots'} />

        <DescriptionSection desc={props.headerData?.desc} lineLimit={5} />

        <InfoSection
            data={props?.infoSectionData?.data}
            title={props?.infoSectionData?.title}
            linkPath={props?.infoSectionData?.linkPath}
        />

        <RatingSection ratingData={props?.ratingData} />

        <AdditionalInfo infoData={props?.additionalInfoData} />

        {/* Recommendation */}
        <div className='mb-5 px-3'>
            <CategorySection title="Recomendation" cards={topTrendingData} category={'AI'} />
        </div>
    </div>
);


const Sidebar = () => (
    <div className="w-full md:w-1/3 mt-8 md:mt-0">
        <h2 className="text-3xl font-bold mb-4">PEOPLE ALSO VIEW</h2>
        <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
            {topTrendingData.map((game, index) => (
                <GameCard key={index} game={game} bg={'rgba(121, 47, 255, 0.15)'} />
            ))}
        </div>
    </div>
);

export default AiDetailsScreen