import React, { useState } from 'react';

interface FAQItemProps {
    question: string;
    answer: string
}

interface FAQProps {
    questions: FAQItemProps[];
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div className="border-b border-gray-700">
            <button
                className="flex justify-between items-center w-full py-5 text-left text-white"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{question ?? ''}</span>
                <span className="text-2xl">{isOpen ? '-' : '+'}</span>
            </button>
            {isOpen && (
                <div className="pb-5 text-gray-400">
                    {answer ?? ''}
                </div>
            )}
        </div>
    );
};

const FAQ: React.FC<FAQProps> = ({ questions }) => {

    return (
        <div className="text-white h-full py-24">
            <h2 className="text-4xl font-bold mb-12">FREQUENTLY ASKED QUESTIONS</h2>
            <div className="w-full px-3 mx-auto">
                {questions.map((question: FAQItemProps, index: number) => (
                    <FAQItem key={index} {...question} />
                ))}
            </div>
        </div>
    );
};

export default FAQ;