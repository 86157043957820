import { useState } from 'react';
import demo1 from '../../../assets/images/explore/category/movies/Frame1321315182.png';
import demo2 from '../../../assets/images/explore/category/movies/image31.png';
import demo3 from '../../../assets/images/explore/category/movies/image33.png';
import { LineDraw } from './MovieDetailsScreen';

// YouTube trailer URL for "Oppenheimer"
const trailerUrl = 'https://www.youtube.com/embed/uYPbbksJxIg';

function Trailor() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<string | undefined>(undefined);
    const [isTrailer, setIsTrailer] = useState(false);

    // Function to open the modal with specific content
    const openModal = (content: string, trailer: boolean = false) => {
        setModalContent(content);
        setIsTrailer(trailer);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(undefined); // Clear content when closing
        setIsTrailer(false);
    };

    return (
        <div className="mb-3 bg-[#111924] rounded-2xl p-5">
            <h2 className="text-xl font-bold mb-4">Trailor</h2>
            <LineDraw />
            <div className="flex overflow-x-auto space-x-4">
                <img
                    src={demo1}
                    alt="Screenshot 1"
                    className="w-72 h-48 rounded-lg cursor-pointer"
                    onClick={() => openModal(trailerUrl, true)}
                />
                <img
                    src={demo2}
                    alt="Screenshot 2"
                    className="w-72 h-48 rounded-lg cursor-pointer"
                    onClick={() => openModal(demo2)}
                />
                <img
                    src={demo3}
                    alt="Screenshot 3"
                    className="w-72 h-48 rounded-lg cursor-pointer"
                    onClick={() => openModal(demo3)}
                />
                <img
                    src={demo1}
                    alt="Screenshot 1"
                    className="w-72 h-48 rounded-lg cursor-pointer"
                    onClick={() => openModal(trailerUrl, true)}
                />
                <img
                    src={demo2}
                    alt="Screenshot 2"
                    className="w-72 h-48 rounded-lg cursor-pointer"
                    onClick={() => openModal(demo2)}
                />
                <img
                    src={demo3}
                    alt="Screenshot 3"
                    className="w-72 h-48 rounded-lg cursor-pointer"
                    onClick={() => openModal(demo3)}
                />
                <img
                    src={demo1}
                    alt="Screenshot 1"
                    className="w-72 h-48 rounded-lg cursor-pointer"
                    onClick={() => openModal(trailerUrl, true)}
                />
                <img
                    src={demo2}
                    alt="Screenshot 2"
                    className="w-72 h-48 rounded-lg cursor-pointer"
                    onClick={() => openModal(demo2)}
                />
                <img
                    src={demo3}
                    alt="Screenshot 3"
                    className="w-72 h-48 rounded-lg cursor-pointer"
                    onClick={() => openModal(demo3)}
                />
            </div>

            {/* Modal for trailer or images */}
            {isModalOpen && modalContent && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
                    <div className="bg-white rounded-lg p-4 relative">
                        <button onClick={closeModal} className="absolute top-2 right-2 text-xl font-bold">X</button>
                        {isTrailer ? (
                            <iframe
                                width="560"
                                height="315"
                                src={modalContent}
                                title="Oppenheimer Trailer"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <img src={modalContent} alt="Modal Content" className="max-w-full max-h-full" />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Trailor;
