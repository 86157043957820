import { useEffect, useState } from 'react';

const RatingDisplay = ({ starDistribution }: any) => {
    const [rating, setRating] = useState(4.8);
    const [totalRatings, setTotalRatings] = useState(14.7);


    useEffect(() => {
        // Calculate the average rating based on star distribution
        const totalStars = starDistribution?.reduce((acc: number, curr: any) => acc + curr.stars * curr.percentage, 0);
        const totalPercentage = starDistribution?.reduce((acc: number, curr: any) => acc + curr.percentage, 0);
        const calculatedRating: any = totalStars / totalPercentage;
        setRating(calculatedRating.toFixed(1));
    }, [starDistribution]);

    return (
        <div className="text-white rounded-lg max-w-md mx-auto">
            <div className='flex'>
                <div className="flex flex-col gap-3 items-center mb-4 justify-center">
                    <span className="text-7xl font-bold mr-4">{rating}</span>
                    <span className="text-md font-bold mr-4">{totalRatings}M Ratings</span>
                </div>
                <div className="space-y-2 min-w-[200px] md:min-w-[300px]">
                    {starDistribution?.map((item: any) => (
                        <div key={item?.stars} className="flex items-center">
                            <span className="w-8 text-sm flex items-center">{item.stars}
                                <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                                    <path d="M8.65625 0L11.7721 4.7114L17.2158 6.21885L13.6978 10.6381L13.9463 16.2812L8.65625 14.301L3.36618 16.2812L3.6147 10.6381L0.0967417 6.21885L5.5404 4.7114L8.65625 0Z" fill="#FD8E1F" />
                                </svg>
                            </span>
                            <div className="flex-grow rounded-full h-2 ml-2" style={{ background: 'rgba(253, 142, 31, 0.40)' }}>
                                <div
                                    className="bg-[#FD8E1F] h-2 rounded-full"
                                    style={{ width: `${item.percentage}%` }}
                                ></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RatingDisplay;