import { LineDraw } from "../GameDetailsScreen";


interface InfoSectionProps {
    publisher: string;
    genres: string;
    category: string;
    productSize: string;
    ageRating: string;
    support: {
        phoneNumber: string;
        email: string;
    };
    compatibility: {
        devices: string[];
        requirements: string[];
    };
}
function AdditionalInfo({ infoData }: any) {
    const InfoItem = ({ title, content }: { title: string, content: string | any }) => (
        <div>
            <h3 className="text-[#6DDCFF] uppercase text-xl font-semibold mb-1">{title}</h3>
            <div className="text-white text-lg">{content}</div>
        </div>
    );

    const InfoSection = ({
        publisher,
        genres,
        category,
        productSize,
        ageRating,
        support,
        compatibility
    }: InfoSectionProps) => {
        return (
            <div className="text-sm p-3 pb-5">
                <div className="flex flex-col md:flex-row gap-3">
                    <div className="flex-1 flex flex-col gap-7 justify-start">
                        <InfoItem title="PUBLISHER:" content={publisher} />
                        <InfoItem title="GENRES" content={genres} />
                        <InfoItem title="CATEGORY" content={category} />
                    </div>
                    <div className="flex-1 flex flex-col justify-start gap-7">
                        <InfoItem title="PRODUCT SIZE" content={productSize} />
                        <InfoItem title="AGE RATING" content={ageRating} />
                        {support && <InfoItem
                            title="SUPPORT"
                            content={
                                <>
                                    Phone Number: {support?.phoneNumber}
                                    <br />
                                    Email: {support?.email}
                                </>
                            }
                        />}
                    </div>
                    <div className="flex-1 flex flex-col justify-start gap-7">
                        <InfoItem
                            title="COMPATIBILITY"
                            content={
                                <ul className="list-disc pl-4">
                                    {compatibility.devices.map((device, index) => (
                                        <li key={index}>{device}</li>
                                    ))}
                                    {compatibility.requirements.map((requirement, index) => (
                                        <li key={index}>{requirement}</li>
                                    ))}
                                </ul>
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="mb-3 bg-[#111924] rounded-2xl p-5">
            <h2 className="text-3xl font-bold mb-4">Additional Information</h2>
            <LineDraw />
            <InfoSection {...infoData} />
        </div>
    )
}

export default AdditionalInfo