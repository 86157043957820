import React from 'react';

type LogoSliderProps = {
    logos: string[];
};

const LogoSlider: React.FC<LogoSliderProps> = ({ logos }) => {
    return (
        <div className="py-8">
            <div className="text-center text-white text-lg pb-6 px-3">
                TRUSTED BY THE WORLD'S MOST INNOVATIVE CLIENTS
            </div>

            <div className="flex flex-wrap justify-center items-center mt-5 space-y-5">
                {logos.map((logo, index) => (
                    <div key={index} className="px-4 w-1/4 md:w-auto">
                        <img
                            src={logo}
                            alt={`Logo ${index + 1}`}
                            className="h-12 w-auto object-contain mx-3"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LogoSlider;