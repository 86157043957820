import { useEffect, useRef, useState } from 'react';
import { scrollLeft, scrollRight } from '../../../../hooks/common.utils';
import { enableHorizontalScroll } from '../../../../lib/ScrollHelper';
import { LineDraw } from '../GameDetailsScreen';
import { Dialog, DialogContent, DialogTrigger } from "./UiDialouge";

function Screenshots({ screenshotData, title }: { screenshotData: string[] | any, title: string }) {
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [error, setError] = useState(false);

    const scrollRef = useRef<HTMLDivElement | null | any>(null);

    useEffect(() => {
        const cleanup = enableHorizontalScroll(scrollRef.current);

        return () => {
            if (cleanup) cleanup();
        };
    }, []);

    const [showLeft, setShowLeft] = useState(false);
    const [showRight, setShowRight] = useState(true);

    const checkScrollPosition = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            setShowLeft(scrollLeft > 0);
            setShowRight(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        const handleScroll = () => checkScrollPosition();

        const currentScrollRef = scrollRef.current;
        currentScrollRef.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);


    return (
        // <div className="mb-3 bg-[#111924] rounded-2xl p-5">
        //     <div className="flex justify-between items-center mb-4">
        //         <h2 className="text-3xl font-bold mb-4 capitalize">{title}</h2>
        //         <div className="flex gap-5 items-center">
        //             {/* left scroll control */}
        //             <button
        //                 onClick={() => scrollLeft(scrollRef)} // Add click event for left scroll
        //                 className="bg-[#2E246C] rounded-full flex items-center justify-center ps-1 pt-1"
        //                 style={{ border: '1px solid var(--outline, rgba(108, 140, 255, 0.50))' }}>
        //                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
        //                     <path d="M16.25 11.725L3.25 11.725M3.25 11.725L9.1 17.575M3.25 11.725L9.1 5.875" stroke="#5B97FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        //                 </svg>
        //             </button>

        //             {/* right scroll control */}
        //             <button
        //                 onClick={() => scrollRight(scrollRef)} // Add click event for right scroll
        //                 className="bg-[#2E246C] rounded-full flex items-center justify-center ps-1 pt-1"
        //                 style={{ border: '1px solid var(--outline, rgba(108, 140, 255, 0.50))' }}>
        //                 <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
        //                     <path d="M4 10.6031L17 10.6031M17 10.6031L11.15 4.75312M17 10.6031L11.15 16.4531" stroke="#5B97FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        //                 </svg>
        //             </button>
        //         </div>
        //     </div>

        //     <LineDraw />
        //     <div className="flex overflow-x-auto space-x-4 pb-2" ref={scrollRef}>
        //         {screenshotData?.map((file: any, i: number) => (
        //             <Dialog key={i}>
        //                 <DialogTrigger asChild>
        //                     {file.video ? (
        //                         <div>
        //                             <div className="relative w-[30rem] h-[15rem] cursor-pointer group">
        //                                 <img
        //                                     src={file.img}
        //                                     alt={`Screenshot ${i + 1}`}
        //                                     className="w-[30rem] h-[15rem] object-cover"
        //                                 />
        //                                 <div className="absolute inset-0 flex items-center justify-center z-99
        //                                  bg-black bg-opacity-20 opacity-100 transition-opacity duration-300">
        //                                     <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
        //                                         <path opacity="0.8" d="M26.4177 4.63672C22.089 4.63672 17.8574 5.92034 14.2582 8.32525C10.659 10.7302 7.8538 14.1483 6.19726 18.1476C4.54073 22.1468 4.10731 26.5474 4.9518 30.793C5.79629 35.0385 7.88077 38.9383 10.9416 41.9992C14.0025 45.0601 17.9023 47.1445 22.1479 47.989C26.3934 48.8335 30.794 48.4001 34.7933 46.7436C38.7925 45.087 42.2107 42.2818 44.6156 38.6826C47.0205 35.0834 48.3041 30.8519 48.3041 26.5231C48.3041 23.649 47.738 20.803 46.6381 18.1476C45.5382 15.4922 43.9261 13.0794 41.8937 11.0471C39.8614 9.01476 37.4486 7.40262 34.7933 6.30272C32.1379 5.20283 29.2918 4.63672 26.4177 4.63672ZM22.0404 36.372V16.6743L35.1722 26.5231L22.0404 36.372Z" fill="#F2F2F2" />
        //                                     </svg>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     ) : (
        //                         <img
        //                             src={file}
        //                             alt={`Screenshot ${i + 1}`}
        //                             className="w-[30rem] h-[15rem] object-cover cursor-pointer"
        //                         />
        //                     )}
        //                 </DialogTrigger>
        //                 <DialogContent className="max-w-7xl">
        //                     {file.video ? (
        //                         <video src={file.video} controls className="w-full max-h-[80vh] object-contain" />
        //                     ) : (
        //                         <img src={file} alt={`Screenshot ${i + 1}`} className="w-full max-h-[80vh] object-contain" />
        //                     )}
        //                 </DialogContent>
        //             </Dialog>
        //         ))}
        //     </div>

        // </div>
        <div className="mb-3 bg-[#111924] rounded-2xl p-5 relative">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-3xl font-bold mb-4 capitalize">{title}</h2>
            </div>

            <LineDraw />

            <div className="relative">
                {/* Left scroll control */}
                {showLeft && (
                    <button
                        onClick={() => scrollLeft(scrollRef)} // Add click event for left scroll
                        className="z-20 absolute left-0 md:-left-5 top-1/2 transform -translate-y-1/2 gradient-span rounded-full p-1.5"
                        style={{ border: '1px solid var(--outline, rgba(108, 140, 255, 0.50))' }}>
                        <svg className='transform rotate-[180deg]' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 25 25" fill="none">
                            <path d="M12.5 2.75C10.5716 2.75 8.68657 3.32183 7.08319 4.39317C5.47982 5.46451 4.23013 6.98726 3.49218 8.76884C2.75422 10.5504 2.56114 12.5108 2.93735 14.4021C3.31355 16.2934 4.24215 18.0307 5.60571 19.3943C6.96928 20.7579 8.70656 21.6865 10.5979 22.0627C12.4892 22.4389 14.4496 22.2458 16.2312 21.5078C18.0127 20.7699 19.5355 19.5202 20.6068 17.9168C21.6782 16.3134 22.25 14.4284 22.25 12.5C22.2473 9.91498 21.2192 7.43661 19.3913 5.60872C17.5634 3.78084 15.085 2.75273 12.5 2.75ZM12.5 20.75C10.8683 20.75 9.27326 20.2661 7.91655 19.3596C6.55984 18.4531 5.50242 17.1646 4.878 15.6571C4.25358 14.1496 4.0902 12.4908 4.40853 10.8905C4.72685 9.29016 5.51259 7.82015 6.66637 6.66637C7.82016 5.51259 9.29017 4.72685 10.8905 4.40852C12.4909 4.09019 14.1497 4.25357 15.6571 4.87799C17.1646 5.50242 18.4531 6.55984 19.3596 7.91655C20.2661 9.27325 20.75 10.8683 20.75 12.5C20.7475 14.6873 19.8775 16.7843 18.3309 18.3309C16.7843 19.8775 14.6873 20.7475 12.5 20.75ZM16.7806 11.9694C16.8504 12.039 16.9057 12.1217 16.9434 12.2128C16.9812 12.3038 17.0006 12.4014 17.0006 12.5C17.0006 12.5986 16.9812 12.6962 16.9434 12.7872C16.9057 12.8783 16.8504 12.961 16.7806 13.0306L13.7806 16.0306C13.6399 16.1714 13.449 16.2504 13.25 16.2504C13.051 16.2504 12.8601 16.1714 12.7194 16.0306C12.5786 15.8899 12.4996 15.699 12.4996 15.5C12.4996 15.301 12.5786 15.1101 12.7194 14.9694L14.4397 13.25H8.75C8.55109 13.25 8.36033 13.171 8.21967 13.0303C8.07902 12.8897 8 12.6989 8 12.5C8 12.3011 8.07902 12.1103 8.21967 11.9697C8.36033 11.829 8.55109 11.75 8.75 11.75H14.4397L12.7194 10.0306C12.5786 9.88989 12.4996 9.69902 12.4996 9.5C12.4996 9.30098 12.5786 9.11011 12.7194 8.96937C12.8601 8.82864 13.051 8.74958 13.25 8.74958C13.449 8.74958 13.6399 8.82864 13.7806 8.96937L16.7806 11.9694Z" fill="white" />
                        </svg>
                    </button>
                )}

                {/* Right scroll control */}
                {showRight && (
                    <button
                        onClick={() => scrollRight(scrollRef)} // Add click event for right scroll
                        className="z-20 absolute right-0 md:-right-5 top-1/2 transform -translate-y-1/2 gradient-span rounded-full p-1.5"
                        style={{ border: '1px solid var(--outline, rgba(108, 140, 255, 0.50))' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 25 25" fill="none">
                            <path d="M12.5 2.75C10.5716 2.75 8.68657 3.32183 7.08319 4.39317C5.47982 5.46451 4.23013 6.98726 3.49218 8.76884C2.75422 10.5504 2.56114 12.5108 2.93735 14.4021C3.31355 16.2934 4.24215 18.0307 5.60571 19.3943C6.96928 20.7579 8.70656 21.6865 10.5979 22.0627C12.4892 22.4389 14.4496 22.2458 16.2312 21.5078C18.0127 20.7699 19.5355 19.5202 20.6068 17.9168C21.6782 16.3134 22.25 14.4284 22.25 12.5C22.2473 9.91498 21.2192 7.43661 19.3913 5.60872C17.5634 3.78084 15.085 2.75273 12.5 2.75ZM12.5 20.75C10.8683 20.75 9.27326 20.2661 7.91655 19.3596C6.55984 18.4531 5.50242 17.1646 4.878 15.6571C4.25358 14.1496 4.0902 12.4908 4.40853 10.8905C4.72685 9.29016 5.51259 7.82015 6.66637 6.66637C7.82016 5.51259 9.29017 4.72685 10.8905 4.40852C12.4909 4.09019 14.1497 4.25357 15.6571 4.87799C17.1646 5.50242 18.4531 6.55984 19.3596 7.91655C20.2661 9.27325 20.75 10.8683 20.75 12.5C20.7475 14.6873 19.8775 16.7843 18.3309 18.3309C16.7843 19.8775 14.6873 20.7475 12.5 20.75ZM16.7806 11.9694C16.8504 12.039 16.9057 12.1217 16.9434 12.2128C16.9812 12.3038 17.0006 12.4014 17.0006 12.5C17.0006 12.5986 16.9812 12.6962 16.9434 12.7872C16.9057 12.8783 16.8504 12.961 16.7806 13.0306L13.7806 16.0306C13.6399 16.1714 13.449 16.2504 13.25 16.2504C13.051 16.2504 12.8601 16.1714 12.7194 16.0306C12.5786 15.8899 12.4996 15.699 12.4996 15.5C12.4996 15.301 12.5786 15.1101 12.7194 14.9694L14.4397 13.25H8.75C8.55109 13.25 8.36033 13.171 8.21967 13.0303C8.07902 12.8897 8 12.6989 8 12.5C8 12.3011 8.07902 12.1103 8.21967 11.9697C8.36033 11.829 8.55109 11.75 8.75 11.75H14.4397L12.7194 10.0306C12.5786 9.88989 12.4996 9.69902 12.4996 9.5C12.4996 9.30098 12.5786 9.11011 12.7194 8.96937C12.8601 8.82864 13.051 8.74958 13.25 8.74958C13.449 8.74958 13.6399 8.82864 13.7806 8.96937L16.7806 11.9694Z" fill="white" />
                        </svg>
                    </button>
                )}

                <div className="flex overflow-x-auto space-x-4 pb-2" ref={scrollRef}>
                    {screenshotData?.map((file: string | any, i: any) => (
                        <Dialog key={i}>
                            <DialogTrigger asChild>
                                {file.video ? (
                                    <div>
                                        <div className="relative w-[30rem] h-[15rem] cursor-pointer group">
                                            <img
                                                src={file.img}
                                                alt={`Screenshot ${i + 1}`}
                                                className="w-[30rem] h-[15rem] object-cover"
                                            />
                                            <div className="absolute inset-0 flex items-center justify-center z-99
                                            bg-black bg-opacity-20 opacity-100 transition-opacity duration-300">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                                                    <path opacity="0.8" d="M26.4177 4.63672C22.089 4.63672 17.8574 5.92034 14.2582 8.32525C10.659 10.7302 7.8538 14.1483 6.19726 18.1476C4.54073 22.1468 4.10731 26.5474 4.9518 30.793C5.79629 35.0385 7.88077 38.9383 10.9416 41.9992C14.0025 45.0601 17.9023 47.1445 22.1479 47.989C26.3934 48.8335 30.794 48.4001 34.7933 46.7436C38.7925 45.087 42.2107 42.2818 44.6156 38.6826C47.0205 35.0834 48.3041 30.8519 48.3041 26.5231C48.3041 23.649 47.738 20.803 46.6381 18.1476C45.5382 15.4922 43.9261 13.0794 41.8937 11.0471C39.8614 9.01476 37.4486 7.40262 34.7933 6.30272C32.1379 5.20283 29.2918 4.63672 26.4177 4.63672ZM22.0404 36.372V16.6743L35.1722 26.5231L22.0404 36.372Z" fill="#F2F2F2" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <img
                                        src={file}
                                        alt={`Screenshot ${i + 1}`}
                                        className="w-[30rem] h-[15rem] object-cover cursor-pointer"
                                    />
                                )}
                            </DialogTrigger>
                            <DialogContent className="max-w-7xl">
                                {file.video ? (
                                    <video src={file.video} controls className="w-full max-h-[80vh] object-contain" />
                                ) : (
                                    <img src={file} alt={`Screenshot ${i + 1}`} className="w-full max-h-[80vh] object-contain" />
                                )}
                            </DialogContent>
                        </Dialog>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Screenshots;