import React from 'react';
import starIcon from '../../assets/images/home/star.svg';

interface Item {
    id: number;
    icon: string;
    title: string;
    genre: string;
}

interface ProductCardProps {
    item: Item;
    navigateDetails: (id: number) => void;
}

const ProductCardNew: React.FC<ProductCardProps> = ({ item, navigateDetails }) => {
    return (
        <div className="relative rounded-xl group overflow-hidden border-2 border-transparent hover:border-white hover:p-1.5 transition-all duration-300">
            <div
                className="h-[299px] relative overflow-hidden"
                onClick={() => navigateDetails(item.id)}
                style={{
                    backgroundImage: `url(${item.icon})`,
                    backgroundSize: 'cover',
                }}
            >
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-60 transition-opacity duration-300"></div>
                <div className="flex justify-between relative z-10 h-full p-3">
                    <div className="w-full flex flex-col gap-y-2 rounded-b-3xl absolute bottom-2 bg-transparent flex-wrap">
                        <h3 className="text-white font-semibold text-10 uppercase">
                            {item.title}
                        </h3>
                        <p className="text-white text-xs font-normal text-8 text-pretty">
                            {item.genre}
                        </p>
                        <p className="flex gap-2 items-center justify-start">
                            <span className="text-[#00F0FB]">4.6</span>
                            <div>
                                <img src={starIcon} alt="star" className="h-2 lg:h-4 w-2 lg:w-auto" />
                            </div>
                        </p>
                    </div>
                    <div className="absolute bottom-4 right-2">
                        <span className="bg-[#3B2C94] px-3 lg:px-4 py-1 text-white text-xs rounded-2xl">TAGS</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCardNew;
