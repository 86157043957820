import starIcon from '../../../../assets/images/explore/category/games/star.svg';

const Header = ({ headerData }: any) => (
    <div className="flex items-center mb-8 gap-5">
        <div className='w-[200px] h-[200px] rounded-2xl'>
            <img src={headerData?.logo} alt={`${headerData?.title} logo`} />
        </div>
        <div className='flex flex-col justify-between gap-3'>
            <h1 className="text-4xl font-bold">{headerData?.title}</h1>
            <div className="flex items-center gap-2">
                <p className="text-white text-md flex flex-wrap md:flex-nowrap items-center">
                    <span>{headerData?.rating}</span>
                    <div className='h-4 w-4 mx-2'>
                        <img src={starIcon} alt='star' />
                    </div>
                    <span> | {headerData?.ratingCount}  {headerData?.category}</span>
                </p>
            </div>
            <div className="flex flex-col gap-3">
                <div>
                    <p className='text-[#6DDCFF] font-semibold'>Category</p>
                    <p className='text-white'>{headerData.category}</p>
                </div>
                <div>
                    <p className='text-[#6DDCFF] font-semibold'>Age Rating</p>
                    <p className='text-white'>{headerData.ageRating}</p>
                </div>
            </div>
        </div>
    </div>
);


export default Header