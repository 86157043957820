import { useEffect, useRef } from 'react';
import { enableHorizontalScroll } from '../../../../lib/ScrollHelper';
import { LineDraw } from '../GameDetailsScreen';
import { Dialog, DialogContent, DialogTrigger } from "./UiDialouge";

function Characters({ characterData, title }: { characterData: string[] | any, title: string }) {

    const scrollRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const cleanup = enableHorizontalScroll(scrollRef.current);

        return () => {
            if (cleanup) cleanup();
        };
    }, []);

    return (
        <div className="mb-3 bg-[#111924] rounded-2xl p-5">
            <h2 className="text-3xl font-bold mb-4 capitalize">{title ?? ''}</h2>
            <LineDraw />
            <div className="flex overflow-x-auto space-x-4 pb-2" ref={scrollRef}>
                {characterData?.map((character: any, i: number) => (
                    <Dialog key={i}>
                        <DialogTrigger asChild>
                            <div className='flex flex-col'><img
                                src={character?.img ?? ''}
                                alt={`Character ${i + 1}`}
                                className="w-[20rem] h-[15rem] object-cover cursor-pointer rounded-lg"
                            />
                                <h5 className="font-bold mb-1">{character?.title ?? ''}</h5>
                                <p>{character?.subTitle ?? ''}</p>
                            </div>
                        </DialogTrigger>
                        <DialogContent className="max-w-7xl">
                            <img src={character?.img ?? ''} alt={`Character ${i + 1}`} className="w-full max-h-[50vh] object-contain" />
                        </DialogContent>
                    </Dialog>
                ))}
            </div>

        </div>
    );
}

export default Characters;