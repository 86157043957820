import { COLOR_ENUM } from '../../constants/colors.constant';
import { checkNullOrEmpty } from '../../utils/utils';

interface BadgeProps {
    value: string;
    textColor?: string;
    textColorEnumKey?: string;
    bgColorEnumKey?: string;
    bgColor?: string;
}

const Badge = ({
    value = '',
    textColor = 'white',
    textColorEnumKey,
    bgColorEnumKey,
    bgColor,
}: BadgeProps) => {
    return (
        <span
            className={`inline-flex items-center rounded-md px-3 py-1 text-xs font-medium text-{${checkNullOrEmpty(textColorEnumKey)
                ? textColor
                : COLOR_ENUM[textColorEnumKey?.toUpperCase() as keyof typeof COLOR_ENUM]
                }}`}
            style={{
                backgroundColor: checkNullOrEmpty(bgColor)
                    ? COLOR_ENUM[bgColorEnumKey?.toUpperCase() as keyof typeof COLOR_ENUM]
                    : bgColor,
            }}
        >
            {value ?? ''}
        </span>
    );
};

export default Badge;
