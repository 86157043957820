import demo1 from '../../../assets/images/explore/category/movies/Frame1321315193.png';
import { LineDraw } from './MovieDetailsScreen';

function Cast() {
    return (
        <div className="mb-3 bg-[#111924] rounded-2xl p-5">
            <h2 className="text-xl font-bold mb-4">Cast</h2>
            <LineDraw />
            <div className="flex overflow-x-auto space-x-4">
                <img src={demo1} alt="Screenshot 1" className="w-72 h-48 rounded-lg" />
                {/* <img src={demo2} alt="Screenshot 2" className="w-72 h-48 rounded-lg" />
                <img src={demo3} alt="Screenshot 3" className="w-72 h-48 rounded-lg" />
                <img src={demo1} alt="Screenshot 1" className="w-72 h-48 rounded-lg" />
                <img src={demo2} alt="Screenshot 2" className="w-72 h-48 rounded-lg" />
                <img src={demo3} alt="Screenshot 3" className="w-72 h-48 rounded-lg" />
                <img src={demo1} alt="Screenshot 1" className="w-72 h-48 rounded-lg" />
                <img src={demo2} alt="Screenshot 2" className="w-72 h-48 rounded-lg" />
                <img src={demo3} alt="Screenshot 3" className="w-72 h-48 rounded-lg" /> */}
            </div>
        </div>
    );
}
export default Cast