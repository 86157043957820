import axios from 'axios';
import Cookies from 'js-cookie';

export const API_BASE_URL = 'https://api.lusso.dev/api/v1';

export const fetchProducts = async () => {
  const token = Cookies.get('authToken');
  const response = await axios.get(`${API_BASE_URL}/products?size=1000`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.products;
};