

const StatsSection = ({ stats }: any) => {
    return (
        <div className="flex flex-col md:flex-row justify-between items-center 
            text-white py-5 md:py-12 space-y-8 md:space-y-0 md:space-x-16">
            {stats.map((stat: any, index: number) => (
                <div key={index} className="flex flex-col md:flex-row gap-2 md:space-x-2 items-center">
                    <div className="text-6xl font-bold">{stat.value}</div>
                    <div className="text-2xl w-[100px] font-semibold">{stat.label}</div>
                </div>
            ))}
        </div>
    );
};

export default StatsSection;
