import { useEffect, useState } from 'react';
import bannerImg from '../../../assets/images/explore/category/games/banner.png';
import demo1 from '../../../assets/images/explore/category/games/img1.png';
import demo2 from '../../../assets/images/explore/category/games/img2.png';
import demo3 from '../../../assets/images/explore/category/games/img3.png';
import logo from '../../../assets/images/explore/category/games/logo.png';
import starIcon from '../../../assets/images/explore/category/games/star.svg';
import GameCard from '../../../components/cards/GameCard';
import { games } from '../TopTrending';
import AdditionalInfo from './Common/AdditionalInfo';
import AvailableAt from './Common/AvailableAt';
import DescriptionSection from './Common/DescriptionSection';
import InfoSection from './Common/InfoSection';
import RatingSection, { RatingSectionProps } from './Common/RatingSection';
import Screenshots from './Common/Screenshots';
import MainBannerWithHeader from './Common/MainBannerWithHeader';

export interface HeaderDataType {
    logo: string;
    title: string;
    rating: number;
    ratingCount: string;
    category: string;
    ageRating: string;
    desc: string
}

export const LineDraw = () => (
    <svg className='mb-5' xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 1077 2" fill="none">
        <path opacity="0.2" d="M4.37222e-08 0.999906L1077 1" stroke="white" />
    </svg>
)

export const screenshotData = [
    {
        video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1
    },
    demo2,
    demo3,
    {
        video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1
    },
    demo2,
    demo3,
    {
        video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1
    },
    demo2,
    demo3,
    {
        video: 'https://videos.pexels.com/video-files/2928382/2928382-hd_1920_1080_30fps.mp4',
        img: demo1
    },
    demo2,
    demo3,
]

const GameDetailsScreen = () => {
    const [selectedOption, setSelectedOption] = useState('Subscription');

    const [headerData, setHeaderData] = useState<HeaderDataType>({
        logo: logo,
        title: 'FIFA24',
        rating: 4.5,
        ratingCount: '330k',
        category: 'Entertainment',
        ageRating: '12+',
        desc: `Looking for the most talked about TV shows and movies from around the world? They're all on Netflix.
        We've got award-winning series, movies, documentaries, and stand-up specials. And with the mobile app, you get
        Netflix while you travel, commute, or just take a break.`
    });

    const [starDistribution, setStarDistribution] = useState([
        { stars: 5, percentage: 70 },
        { stars: 4, percentage: 20 },
        { stars: 3, percentage: 5 },
        { stars: 2, percentage: 3 },
        { stars: 1, percentage: 2 },
    ]);

    const ratingData: RatingSectionProps = {
        starDistribution: starDistribution,
        rating: 4.5,
        reviewTitle: "App is one of the best",
        reviewText: "I have been using Netflix since it came out pretty much, I've always enjoyed it and I have loved everything they have done with the App , it is one of the best streaming platform, provides great quality but the subscription is very expensive, we get only one screen in UE this need to be addressed by Netflix",
        reviewerName: "Ben Cutting",
        reviewDate: "8/27/2024"
    };

    const infoSectionData = {
        title: "Why You'll Like this Bubble Shooter Game?",
        data: [
            "Play hundreds of addicting game levels, packed with fun challenging puzzles",
            "Become a great time-cruncher by solving Kingdom bubble shooting legend, and enjoy the classic smooth gameplay",
            "Beat your stars, complete puzzle quests, and collect awesome rewards",
            "Master the epic game time target and win",
            "Relaxing, stress relief time killer game to play whenever and wherever you want",
            "Fight alongside the bravest warriors of the kingdom and conquer all the amazing challenges",
            "Boost your experience with awesome power-ups and boosters and pop all the colorful balloons"
        ],
        linkPath: ""
    }

    const additionalInfoData = {
        publisher: "Netflix, Inc.",
        genres: "Entertainment",
        category: "Apps/Entertainment",
        productSize: "50 GB",
        ageRating: "PG-18",
        support: {
            phoneNumber: "+18667160414",
            email: "iosappstore@netflix.com"
        },
        compatibility: {
            devices: [
                "iPhone",
                "iPad",
                "iPod touch"
            ],
            requirements: [
                "Requires iOS 12.0 or later.",
                "Requires iPadOS 12.0 or later."
            ]
        }
    };

    // init
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },);


    // render
    return (
        <div className="alignCenter bg-blurred-new text-white min-h-screen">
            {/* <MainBanner /> */}
            <MainBannerWithHeader bannerImg={bannerImg} headerData={headerData} />

            <div className='content-new'>
                <div className="lg:max-w-[80%] mx-auto px-4 py-8">
                    {/* <Header headerData={headerData} /> */}
                    <div className="flex flex-col md:flex-row md:space-x-8">
                        <MainContent
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            headerData={headerData}
                            ratingData={ratingData}
                            screenshotData={screenshotData}
                            infoSectionData={infoSectionData}
                            additionalInfoData={additionalInfoData}
                        />
                        <Sidebar />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MainBanner = () => (
    <div className="explore-cat-banner">
        {/* This could be an image or video */}
        <img src={bannerImg} alt="FIFA 24 Banner" className="explore-cat-banner" />
    </div>
);

const Header = ({ headerData }: any) => (
    <div className="flex items-center mb-8 gap-5">
        <div className='w-[200px] h-[200px] rounded-2xl'>
            <img src={headerData?.logo} alt={`${headerData?.title} logo`} />
        </div>
        <div className='flex flex-col justify-between gap-3'>
            <h1 className="text-4xl font-bold">{headerData?.title}</h1>
            <div className="flex items-center gap-2">
                <p className="text-white text-md flex flex-wrap md:flex-nowrap items-center">
                    <span>{headerData?.rating}</span>
                    <div className='h-4 w-4 mx-2'>
                        <img src={starIcon} alt='star' />
                    </div>
                    <span> | {headerData?.ratingCount} ratings | {headerData?.category}</span>
                </p>
            </div>
            <div className="flex flex-col gap-3">
                <div>
                    <p className='text-[#6DDCFF] font-semibold'>Category</p>
                    <p className='text-white'>{headerData.category}</p>
                </div>
                <div>
                    <p className='text-[#6DDCFF] font-semibold'>Age Rating</p>
                    <p className='text-white'>{headerData.ageRating}</p>
                </div>
            </div>
        </div>
    </div>
);

const MainContent = (props: any) => (
    <div className="w-full md:w-2/3">
        <Screenshots
            screenshotData={props?.screenshotData}
            title={'Screenshots'} />

        <AvailableAt
            selectedOption={props?.selectedOption}
            setSelectedOption={props?.setSelectedOption} />

        <DescriptionSection desc={props.headerData?.desc} lineLimit={5} />

        <InfoSection
            data={props?.infoSectionData?.data}
            title={props?.infoSectionData?.title}
            linkPath={props?.infoSectionData?.linkPath}
        />
        <RatingSection ratingData={props?.ratingData} />

        <AdditionalInfo infoData={props?.additionalInfoData} />
    </div>
);


const Sidebar = () => (
    <div className="w-full md:w-1/3 mt-8 md:mt-0">
        <h2 className="text-3xl font-bold mb-4">PEOPLE ALSO VIEW</h2>
        <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
            {games.map((game, index) => (
                <GameCard key={index} game={game} bg={'rgba(121, 47, 255, 0.15)'} />
            ))}
        </div>
    </div>
);

export default GameDetailsScreen