import React from 'react'
import Header from './Header'

interface MainBannerWithHeaderProps {
    bannerImg: string
    headerData: any
}

export default function MainBannerWithHeader({ bannerImg, headerData }: MainBannerWithHeaderProps) {
    return (
        <div className="relative w-full md:h-[50rem] overflow-hidden rounded-lg">
            {/* Blurred background image */}
            <div
                className="hidden md:block md:absolute inset-0 bg-cover bg-center"
                style={{
                    backgroundImage: `url(${bannerImg})`,
                    filter: 'blur(2px)',
                    transform: 'scale(1.1)'
                }}
            />

            {/* Gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent" />

            {/* Content */}
            <div className="relative h-full flex justify-center items-end">
                <div className='content-new'>
                    <div className="lg:max-w-[80%] mx-auto px-4 py-8">
                        <Header headerData={headerData} />
                    </div>
                </div>
            </div>
        </div>
    )
}