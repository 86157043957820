import { useEffect, useRef, useState } from 'react';
//import rightArrow from "../../assets/images/rightArrowIcon.svg"
import appleTv from '../../assets/images/apple-tv.png';
import defaultIcon from '../../assets/images/icons/flickr.svg';
import Udemy from '../../assets/images/icons/udemy.svg';
import playstore from '../../assets/images/playstore.svg';
import primeVideo from '../../assets/images/primevideo.svg';
import vimeo from '../../assets/images/vimeo.svg';
import youtube from '../../assets/images/youtube.svg';
import './NewProductDetails.scss';

import playstore_icon from '../../assets/images/playstore.svg';

import starIcon from '../../assets/images/explore/category/games/star.svg';
import netflix_title from '../../assets/images/netflix-title.png';

import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import GameCard from '../../components/cards/GameCard';
import AdditionalInfo from '../explore/categoryDetails/Common/AdditionalInfo';
import AvailableAt from '../explore/categoryDetails/Common/AvailableAt';
import DescriptionSection from '../explore/categoryDetails/Common/DescriptionSection';
import InfoSection from '../explore/categoryDetails/Common/InfoSection';
import RatingSection from '../explore/categoryDetails/Common/RatingSection';
import Screenshots from '../explore/categoryDetails/Common/Screenshots';
import { additionalInfoData } from '../explore/categoryDetails/staticData';
import CategorySection from '../explore/CategorySection';
import { appsData } from '../explore/ExploreAll';

type IconMap = {
    [key: string]: string;
};

const screenshotsArr = [
    'https://beebom.com/wp-content/uploads/2022/01/how-to-screenshot-netflix.jpg',
    'https://www.gizchina.com/wp-content/uploads/images/2023/01/imagem_2023-01-31_094302152.png',
    'https://qph.cf2.quoracdn.net/main-qimg-70da4715d61313aeca1632f8ef490f3b-lq',
    // 'https://beebom.com/wp-content/uploads/2022/01/how-to-screenshot-netflix.jpg',
    // 'https://www.gizchina.com/wp-content/uploads/images/2023/01/imagem_2023-01-31_094302152.png',
    // 'https://qph.cf2.quoracdn.net/main-qimg-70da4715d61313aeca1632f8ef490f3b-lq',
];

export const availableAtInfo = [
    {
        logo: playstore_icon,
        description: `Lorem ipsum dolor sit amet consectetur, adipisicing
                                                        elit. Laborum nostrum beatae rerum earum iusto quae
                                                        blanditiis quod maiores fugiat repellendus, minima
                                                        velit iste doloribus cum voluptate quia consequatur,
                                                        rem quam.`,
        purchaseTxt: '',
        price: '10.99/ month',
        subtext: 'Offers In-App Purchases',
    },
    {
        logo: playstore_icon,
        description: `Lorem ipsum dolor sit amet consectetur, adipisicing
                                                        elit. Laborum nostrum beatae rerum earum iusto quae
                                                        blanditiis quod maiores fugiat repellendus, minima
                                                        velit iste doloribus cum voluptate quia consequatur,
                                                        rem quam.`,
        purchaseTxt: '',
        price: '10.99/ month',
        subtext: 'Offers In-App Purchases',
    },
];

const infoSectionData = {
    title: 'Features',
    data: [
        'We add TV shows and movies all the time. Browse new titles or search for your favorites, and stream videos right on your device.',
        'The more you watch, the better Netflix gets at recommending TV shows and movies you’ll love.',
        'Beat your stars, complete puzzle quests, and collect awesome rewards',
        'Master the epic game time target and win',
        'Relaxing, stress relief time killer game to play whenever and wherever you want',
        'Fight alongside the bravest warriors of the kingdom and conquer all the amazing challenges',
        'Boost your experience with awesome power-ups and boosters and pop all the colorful balloons',
    ],
    linkPath: '',
};

export interface Tag {
    name?: string;
    selected?: boolean;
}

export interface HeaderDataType {
    logo?: string;
    title?: string;
    rating?: number;
    ratingCount?: string;
    ageRating: string;
    category?: string;
    desc?: string;
    tags?: Tag[];
}

const NewProductDetails = () => {
    const [selectedOption, setSelectedOption]: any = useState('All');
    const [avlType, setAvlType]: any = useState('All');
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const [loading, setLoading]: any = useState(true);
    const [hoveredCategory, setHoveredCategory]: any = useState();
    const [productInfo, setProductInfo]: any = useState({});
    const [reviews, setReviews]: any = useState([]);
    const [mediaLinks, setMediaLinks]: any[] = useState([]);
    const [platformList, setPlatformList]: any[] = useState([]);
    const [starDistribution, setStarDistribution] = useState([
        { stars: 5, percentage: 70 },
        { stars: 4, percentage: 20 },
        { stars: 3, percentage: 5 },
        { stars: 2, percentage: 3 },
        { stars: 1, percentage: 2 },
    ]);
    const [headerData, setHeaderData] = useState<HeaderDataType>({
        logo: netflix_title,
        title: 'Netflix',
        rating: 4.5,
        ratingCount: '330k',
        category: 'Entertainment',
        ageRating: '12+',
        tags: [
            { name: 'Educational', selected: false },
            { name: 'Ai', selected: false },
            { name: 'CS', selected: false },
            { name: 'Computer', selected: false },
            { name: 'Biography', selected: false },
            { name: 'Drama', selected: false },
        ],
        desc: `Looking for the most talked about TV shows and movies from around the world? They’re all on Netflix.
We’ve got award-winning series, movies, documentaries, and stand-up specials. And with the mobile app, you get Netflix while you travel, commute, or just take a break.

Netflix membership is a month-to-month subscription that begins at sign up. You can easily cancel anytime, online, 24 hours a day. Looking for the most talked about TV shows and movies from around the world? They’re all on Netflix.
We’ve got award-winning series, movies, documentaries, and stand-up specials. And with the mobile app, you get Netflix while you travel, commute, or just take a break.

Netflix membership is a month-to-month subscription that begins at sign up. You can easily cancel anytime, online, 24 hours a day.`,
    });

    const ratingData = {
        starDistribution: starDistribution,
        rating: 4.5,
        reviewTitle: 'App is one of the best',
        reviewText:
            "I have been using Netflix since it came out pretty much, I've always enjoyed it and I have loved everything they have done with the App , it is one of the best streaming platform, provides great quality but the subscription is very expensive, we get only one screen in UE this need to be addressed by Netflix",
        reviewerName: 'Ben Cutting',
        reviewDate: '8/27/2024',
    };
    // const handleClick = () => {
    //     if (videoRef.current && imageRef.current) {
    //         imageRef.current.style.display = "none";
    //         videoRef.current.style.display = "block";
    //         videoRef.current.play();
    //     }
    // };
    const { productId } = useParams();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    });

    const iconMap: IconMap = {
        Youtube: youtube,
        Appletv: appleTv,
        ' Playstore': playstore,
        Amazonprimevideos: primeVideo,
        ' Vimeo': vimeo,
        Udemy: Udemy,
    };

    // Define the type for your function parameter
    const findIcon = (name: string): string => iconMap[name] || defaultIcon; // Replace `string` with the appropriate return type

    const getProductData = () => {
        setLoading(true);
        const token = Cookies.get('authToken');
        axios
            .get(`https://api.lusso.dev/api/v1/products/${productId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                let productData: any = response.data;
                setMediaLinks(response.data?.mediaLinks);
                setProductInfo(productData);
                console.log('productInfo', response.data);
                let platformData = productData?.sourceLinks;
                let updatedPlatformData: any[] = [];
                if (platformData) {
                    platformData.map((platform: any) => {
                        const platformItem = {
                            icon: findIcon(platform?.name), // Replace with the actual icon if needed
                            subscription: platform?.price,
                            height: '40px',
                            url: platform?.link,
                            quality: platform?.quality,
                            audio: platform?.audio,
                            captions: platform?.captions,
                        };
                        updatedPlatformData.push(platformItem);
                    });
                    setPlatformList(updatedPlatformData);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log('error', error);
                setLoading(false);
            });
    };

    const getReviews = () => {
        setLoading(true);
        const token = Cookies.get('authToken');
        axios
            .get(
                `https://api.lusso.dev/api/v1/reviews?productId=${productId}&page=0&size=100`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
            .then(response => {
                let reviews: any = response.data?.reviews;
                setReviews(reviews);
                console.log('reviews', reviews);
                setLoading(false);
            })
            .catch(error => {
                console.log('error', error);
                setLoading(false);
            });
    };

    return (
        // <div className="product-details-wrapper">
        <div className="alignCenter bg-blurred-new text-white min-h-screen">
            <div className="explore-cat-banner">
                {/* This could be an image or video */}
                {/* <img src={bannerImg} alt="course Banner" className="explore-cat-banner" /> */}
                <video
                    // ref={videoRef}
                    // className="w-full h-full"
                    className="explore-cat-banner w-full"
                    // style={{ display: "none" }}
                    // onClick={handleVideoClick}
                    // onEnded={handleVideoEnded}
                    autoPlay
                    loop
                    muted
                >
                    <source
                        src="https://play-games.googleusercontent.com/vp/mp4/1280x720/R3x88pl2rZI.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className="content-new">
                <div className="lg:max-w-[80%] mx-auto px-4 py-8">
                    <div className="flex items-center mb-8 gap-5">
                        <div className="p-4 flex items-center bg-gradient-to-b from-white to-white/50 w-[200px] h-[200px] rounded-2xl">
                            <img src={headerData?.logo} alt={`${headerData?.title} logo`} />
                        </div>
                        <div className="flex flex-col justify-between gap-3">
                            <h1 className="text-4xl font-bold">{headerData?.title}</h1>
                            <div className="flex items-center gap-2">
                                <p className="text-white text-md flex flex-wrap md:flex-nowrap items-center">
                                    <span>{headerData?.rating}</span>
                                    <div className="h-4 w-4 mx-2">
                                        <img src={starIcon} alt="star" />
                                    </div>
                                    <span>
                                        {' '}
                                        | {headerData?.ratingCount} ratings | {headerData?.category}
                                    </span>
                                </p>
                            </div>
                            <div className="flex flex-col gap-3">
                                <div>
                                    <p className="text-[#6DDCFF] font-semibold">Category</p>
                                    <p className="text-white">{headerData.category}</p>
                                </div>
                                <div>
                                    <p className="text-[#6DDCFF] font-semibold">Age Rating</p>
                                    <p className="text-white">{headerData.ageRating}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row md:space-x-8">
                        <div className="w-full md:w-2/3">
                            <Screenshots
                                screenshotData={screenshotsArr}
                                title={'Screenshots'}
                            />

                            <AvailableAt
                                infoArr={availableAtInfo}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                            />

                            <DescriptionSection desc={headerData?.desc ?? ''} lineLimit={3} />

                            <InfoSection
                                data={infoSectionData?.data}
                                title={infoSectionData?.title}
                                linkPath={infoSectionData?.linkPath}
                            />

                            <RatingSection ratingData={ratingData} />

                            <AdditionalInfo infoData={additionalInfoData} />

                            <div className="mb-5 px-3">
                                <CategorySection
                                    title="Recommendation"
                                    cards={appsData}
                                    category={'Apps'}
                                />
                            </div>
                        </div>
                        <div className="w-full md:w-1/3 mt-8 md:mt-0">
                            <h2 className="text-3xl font-bold mb-4">PEOPLE ALSO VIEW</h2>
                            <div className={`mt-5 grid gap-6 md:grid-cols-1'} `}>
                                {appsData.map((app, index) => (
                                    <GameCard
                                        key={index}
                                        game={app}
                                        bg={'rgba(121, 47, 255, 0.15)'}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewProductDetails;
