
function FilterExplore() {
    return (
        <>
            {/* Advanced Filter Section */}
            <div className="filter-section flex flex-wrap items-center p-6 rounded-lg mb-10">
                {/* Filter by Popularity/Date/Rating */}
                <div className="flex flex-col mr-6 mb-4">
                    <label className="text-white text-lg font-semibold mb-2">Sort By</label>
                    <select className="bg-[#32257E] text-white p-3 rounded-md focus:outline-none">
                        <option value="popularity">Popularity</option>
                        <option value="date">Release Date</option>
                        <option value="rating">Rating</option>
                    </select>
                </div>

                {/* Filter by Price Range */}
                <div className="flex flex-col mr-6 mb-4">
                    <label className="text-white text-lg font-semibold mb-2">Price Range</label>
                    <div className="flex items-center space-x-2">
                        <input
                            type="number"
                            placeholder="Min"
                            className="bg-[#32257E] text-white p-3 rounded-md focus:outline-none w-24"
                        />
                        <span className="text-white">-</span>
                        <input
                            type="number"
                            placeholder="Max"
                            className="bg-[#32257E] text-white p-3 rounded-md focus:outline-none w-24"
                        />
                    </div>
                </div>

                {/* Filter by Category */}
                <div className="flex flex-col mr-6 mb-4">
                    <label className="text-white text-lg font-semibold mb-2">Category</label>
                    <select className="bg-[#32257E] text-white p-3 rounded-md focus:outline-none">
                        <option value="all">All Categories</option>
                        <option value="development">Development</option>
                        <option value="design">Design</option>
                        <option value="marketing">Marketing</option>
                        {/* Add more categories as needed */}
                    </select>
                </div>

                {/* Toggle for Free/Paid Content */}
                <div className="flex flex-col mr-6 mb-4">
                    <label className="text-white text-lg font-semibold mb-2">Content Type</label>
                    <div className="flex items-center space-x-4">
                        <label className="flex items-center text-white">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-yellow-400" />
                            <span className="ml-2">Free</span>
                        </label>
                        <label className="flex items-center text-white">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-yellow-400" />
                            <span className="ml-2">Paid</span>
                        </label>
                    </div>
                </div>

                {/* Apply and Clear Buttons */}
                <div className="flex items-center space-x-4 ml-auto">
                    <button className="bg-green-400 text-black py-2 px-6 rounded-md font-bold hover:bg-yellow-500 transition duration-300">
                        Apply Filters
                    </button>
                    <button className="bg-white text-purple-700 py-2 px-6 rounded-md font-bold hover:bg-gray-200 transition duration-300">
                        Clear Filters
                    </button>
                </div>
            </div>

        </>
    )
}

export default FilterExplore