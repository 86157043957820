import { useNavigate } from "react-router-dom";
import TitleBar from "../../components/common/TitleBar";
import ProductSlider from "./ProductSlider";

const TrendingProducts = (props: any) => {
    const navigate = useNavigate();
    const navigateDetails = (id: number) => navigate(`/dashboard/details/${id}`);
    const { data } = props;

    return (
        <>
            <TitleBar title={"Trending Now"} />
            <ProductSlider items={data} navigateDetails={navigateDetails} />
        </>
    );
};

export default TrendingProducts;
