// import newBanner from '../../assets/images/explore/banner1.png';
// import Promotion from '../../assets/images/Promotion.svg';
// import Promotion2 from '../../assets/images/Promotion2.svg';
import SampleCategory from '../../assets/images/sampleCategory.svg';
import SampleCategory2 from '../../assets/images/SampleCategory2.svg';
import SampleCategory3 from '../../assets/images/SampleCategory3.svg';
import SampleCategory4 from '../../assets/images/SampleCategory4.svg';

import SampleMoviePoster1 from '../../assets/images/NewlyMoviePoster1.svg';
import SampleMoviePoster2 from '../../assets/images/NewlyMoviePoster2.svg';
import SampleMoviePoster3 from '../../assets/images/NewlyMoviePoster3.svg';
import podcastImage from '../../assets/images/SampleMoviePoster.svg';
import MainCategoryCard from '../home/MainCategory';
import NewlyAddedCategory from '../home/NewlyAddedCategory';

import { useEffect, useRef } from 'react';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import { useLocation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { fetchExploreData } from '../../redux/explore/exploreSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { appsData, coursesData, moviesData, recomendationData, servicesData } from '../home/HomeExplore';
import BannerSection from './BannerSection';
import CategorySection from './CategorySection';
import './NewExplore.css';

const NewHomepage: React.FC<{ section?: string }> = ({ section }) => {
    const appsRef = useRef<HTMLDivElement>(null);
    const gamesRef = useRef<HTMLDivElement>(null);
    const moviesTvsRef = useRef<HTMLDivElement>(null);
    const coursesRef = useRef<HTMLDivElement>(null);
    const servicesRef = useRef<HTMLDivElement>(null);
    const artificialRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();
    const { loading, error, apps, games, movies, courses, services, aiProducts } = useAppSelector(
        (state) => state.explore
    );

    useEffect(() => {
        dispatch(fetchExploreData());
    }, [dispatch]);

    const location = useLocation();

    // const [apps, setApps]: any = useState([]);
    // const [games, setGames]: any = useState([]);
    // const [movies, setMovies]: any = useState([]);
    // const [courses, setCourses]: any = useState([]);
    // const [services, setServices]: any = useState([]);
    // const [AIproduct, setAIproduct]: any = useState([]);
    // const [loading, setLoading]: any = useState(true);

    // const getExploreData = () => {
    //     setLoading(true);
    //     const token = Cookies.get('authToken');

    //     axios
    //         .get('https://api.lusso.dev/api/v1/products?size=1000', {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         })
    //         .then(response => {
    //             let products: any[] = response.data.products;
    //             console.log('response.data.products', response.data.products)
    //             let tempApps: any[] = [];
    //             let tempGames: any[] = [];
    //             let tempMovies: any[] = [];
    //             let tempCourses: any[] = [];
    //             let tempServices: any[] = [];
    //             let tempAIProducts: any[] = [];
    //             products.map((product) => {
    //                 let requiredData = {
    //                     imageSrc: product?.exploreImage ? product?.exploreImage : SampleCategory2,
    //                     title: product?.name,
    //                     subtitle: Array.isArray(product?.additionalInfo?.genre) ? product?.additionalInfo?.genre?.join(' | ') : typeof (product?.additionalInfo?.genre) === 'string' ? product?.additionalInfo?.genre : 'LUSSO | SAMPLES',
    //                     productId: product?.productId
    //                 }
    //                 console.log('requiredData', requiredData);
    //                 console.log('product.category', product?.productSpecs?.category);
    //                 let category = product?.productSpecs?.category;
    //                 if (category === 'APPS') {
    //                     tempApps.push(requiredData);
    //                 } else if (category === 'GAMES') {
    //                     tempGames.push(requiredData);
    //                 } else if (category === 'MOVIES') {
    //                     tempMovies.push(requiredData);
    //                 } else if (category === 'COURSES') {
    //                     tempCourses.push(requiredData);
    //                 } else if (category === 'SERVICES') {
    //                     tempServices.push(requiredData);
    //                 } else if (category === 'AI PRODUCT') {
    //                     tempAIProducts.push(requiredData);
    //                 }
    //             });
    //             console.log('tempApps', tempApps);
    //             setApps(tempApps);
    //             setGames(tempGames);
    //             setMovies(tempMovies);
    //             setCourses(tempCourses);
    //             setServices(tempServices);
    //             setAIproduct(tempAIProducts);
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.log('error', error)
    //             setLoading(false);
    //         });
    // };

    useEffect(() => {
        const scrollToSection = (section: string | undefined) => {
            switch (section) {
                case 'aiRef':
                    artificialRef.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'apps':
                    appsRef.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'games':
                    gamesRef.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'movies-tvs':
                    moviesTvsRef.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'courses':
                    coursesRef.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'services':
                    servicesRef.current?.scrollIntoView({ behavior: 'smooth' });
                    break;
                default:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        };
        scrollToSection(section);
    }, [section, location]);

    // useEffect(() => {
    //     getExploreData();
    // }, []);

    // const [banners, setBanners]: any = useState([
    //     { url: newBanner },
    //     { url: Promotion },
    //     { url: Promotion2 },
    // ]);

    const cardData = [
        { imageSrc: SampleCategory, title: 'RED DEAD REDEMPTION II', subtitle: 'ACTION | ADVENTURE | AAA', productId: '1' },
        { imageSrc: SampleCategory2, title: 'AMAZING SPIDER MAN', subtitle: 'ACTION | PVP | COMEDY', productId: '1' },
        { imageSrc: SampleCategory3, title: 'CLASH OF CLANS', subtitle: 'ACTION | PVP | COMEDY', productId: '1' },
        { imageSrc: SampleCategory4, title: 'COD: BLACK OPS', subtitle: 'ACTION | ADVENTURE | STRATEGY', productId: '1' },
        { imageSrc: SampleCategory4, title: 'COD: BLACK OPS', subtitle: 'ACTION | ADVENTURE | STRATEGY', productId: '1' },
        { imageSrc: SampleCategory4, title: 'COD: BLACK OPS', subtitle: 'ACTION | ADVENTURE | STRATEGY', productId: '1' }
    ];

    const newlyAddedData = [
        {
            imageSrc: podcastImage, title: 'TEXAS PODCAST SERVICES'
        },
        {
            imageSrc: SampleMoviePoster1, title: 'TEXAS CAFE'
        },
        {
            imageSrc: SampleMoviePoster2, title: 'TEXAS STYLES & CO.'
        },
        {
            imageSrc: SampleMoviePoster3, title: 'TEXAS BOUQETS'
        },
        {
            imageSrc: podcastImage, title: 'TEXAS PODCAST SERVICES'
        },
        {
            imageSrc: SampleMoviePoster1, title: 'TEXAS CAFE'
        },
        {
            imageSrc: SampleMoviePoster2, title: 'TEXAS STYLES & CO.'
        },
        {
            imageSrc: SampleMoviePoster3, title: 'TEXAS BOUQETS'
        },
        {
            imageSrc: podcastImage, title: 'TEXAS PODCAST SERVICES'
        },
        {
            imageSrc: SampleMoviePoster1, title: 'TEXAS CAFE'
        },
        {
            imageSrc: SampleMoviePoster2, title: 'TEXAS STYLES & CO.'
        },
        {
            imageSrc: SampleMoviePoster3, title: 'TEXAS BOUQETS'
        },
    ];


    // Flag to check data
    let AI_DATA = aiProducts?.length > 0 ? aiProducts : appsData
    let SERVICE_DATA = services?.length > 0 ? services : servicesData
    let COURSE_DATA = courses?.length > 0 ? courses : coursesData
    let GAMES_DATA = games?.length > 0 ? games : moviesData
    let APPS_DATA = apps?.length > 0 ? apps : appsData
    let MOVIES_DATA = movies?.length > 0 ? movies : moviesData

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            {/* <div>
                <img src={Promotion} alt='' />
            </div> */}
            <div className='mb-10 mt-5'>
                {/* <SlickSlider
                    imageList={banners}
                    isExplore={true}
                /> */}
                <BannerSection />
            </div>

            {/* Categories */}
            <div className='mb-5'>
                <h2 className="category-title py-2">categories</h2>
                <MainCategoryCard />
            </div>

            {/* Top 10 */}
            {/* <div className='mb-5'>
                <Category title="PLATFORM’S TOP 10 " cards={cardData} />
            </div> */}

            {/* Top Trending */}
            <div className='mb-5'>
                <CategorySection title="Top Trending" cards={moviesData} category={'movie'} />
            </div>

            {/* Recommendations */}
            <div>
                <CategorySection title="RECOMMENDATIONS" cards={recomendationData} category={'apps'} />
            </div>

            {/* Newly Added */}
            <div className='mb-5'>
                <NewlyAddedCategory title='Newly Added' cards={newlyAddedData.concat(newlyAddedData)} />
            </div>

            {/* AI Products */}
            {AI_DATA?.length > 0 && <div className='mb-5' ref={artificialRef}>
                <CategorySection title="AI Products" cards={AI_DATA} category={'AI'} />
            </div>}

            {/* Services */}
            {SERVICE_DATA?.length > 0 && <div className='mb-5' ref={servicesRef}>
                <CategorySection title="Services" cards={SERVICE_DATA} category={'Service'} />
            </div>}

            {/* Courses */}
            {COURSE_DATA?.length > 0 && <div className='mb-5' ref={coursesRef}>
                <CategorySection title="Courses" cards={COURSE_DATA} category={'Course'} />
            </div>}

            {/* Games */}
            {GAMES_DATA?.length > 0 && <div className='mb-5' ref={gamesRef}>
                <CategorySection title="Games" cards={GAMES_DATA} category={'Game'} />
            </div>}

            {/* Apps */}
            {APPS_DATA?.length > 0 && <div className='mb-5' ref={appsRef}>
                <CategorySection title="Apps" cards={APPS_DATA} category={'Apps'} />
            </div>}

            {/* Movies & Tv */}
            {MOVIES_DATA?.length > 0 && <div className='mb-5' ref={moviesTvsRef}>
                <CategorySection title="Movies & Tv" cards={MOVIES_DATA} category={'Movie'} />
            </div>}

        </div>
    )
}

export default NewHomepage;